import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/Footer';

const PrivacyPolicy = () => {

    const [privacyPolicy, PrivacypolicyData] = useState([]);
    const baseUrl = "https://backend.react.nvds.in/storage/";
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        AOS.init();
        const whyUsApi = async () => {
            const { data } = await axios.get(
                "https://backend.react.nvds.in/api/policy"
            );
            const privacyPolicy = data.data.privacy
            PrivacypolicyData(privacyPolicy);
        };
        whyUsApi();
        setContentLoaded(true);
    }, []);

    return (
        <>

        <section className="pagebanner bgc-primary">
        {privacyPolicy.image == null ? (
                      ""
                    ) : (
            <img className="banner-img" 
            src={baseUrl + privacyPolicy.image} 
            alt="title" 
            loading="lazy" /> )}
            {privacyPolicy.image == null ? (
                          ""
                        ) : (
                <img className="mobile-banner-img" 
                src={baseUrl + privacyPolicy.mobile_image} 
                alt="title" 
                loading="lazy" /> )}
            <div className="container">
                <div className="pagebanner-left">
                <h1 className="text-light">{privacyPolicy.title}</h1>
                </div>
            </div>
        </section>
        <section className="content bg-light">
              <div className="container">
                <div className="content-full text-left">
                        <div dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}></div>
                </div>
            </div>
        </section>
        {contentLoaded && <Footer initiallyHidden={false} />}
        </>
    )
}

export default PrivacyPolicy
