import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import "../pages/services/services.css";
import { BsArrowRight } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { GoogleReCaptcha , GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Footer from '../components/Footer';

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-prev-arrow" onClick={onClick}>
    <IoIosArrowBack />
  </div>
);
const CustomNextArrow = ({ onClick }) => (
  <div className="custom-next-arrow" onClick={onClick}>
    <IoIosArrowForward />
  </div>
);

const scrollToEnquiry = () => {
  const enquirySection = document.getElementById("enquiry");
  if (enquirySection) {
    enquirySection.scrollIntoView({ behavior: "smooth" });
  }
};

const SubServiceDetails = () => {
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [isLoading, setIsLoading] = useState(false);
  const [service_data, setServices_data] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const fetchServices = async () => {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/services"
      );
      const service_data = data.data.services;
      setServices_data(service_data);
    };
    fetchServices();
  }, []);

  var caseSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        caseSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        caseSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        caseSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    margin: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var approach_settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    margin: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [servicedata, setServices] = useState({
    name: "",
    content: [],
    description: "",
  });
  const [sub_service_data, setSub_services] = useState({
    sub_name: [],
  });

  var subDetailsDataSectionArray = [];
  var whyAiConsultingSectionArray = [];
  var partnerSectionArray = [];
  var offerSectionArray = [];
  var ourApproachSectionArray = [];
  var whyChooseSectionArray = [];
  // var caseListSectionArray = [];
  var ourProcessSectionArray = [];

  const [subDetailsDataSection, setSubDetailsDataSection] = useState([]);
  const [whyAiConsultingSection, setWhyAiConsultingSection] = useState([]);
  const [partnerSection, setPartnerSection] = useState([]);
  const [offerSection, setOfferSection] = useState([]);
  const [ourApproachSection, setOurApproachSection] = useState([]);
  const [whyChooseSection, setWhyChooseSection] = useState([]);
  const [ourProcessSection, setOurProcessSection] = useState([]);
  const [weOfferExtraContents, setWeOfferExtraContents] = useState([]);
  const [ourApproachExtraContents, setOurApproachExtraContents] = useState([]);
  const [whyChooseExtraContents, setWhyChooseExtraContents] = useState([]);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [type, setType] = useState("service enquiry");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [contactUs, setContactUs] = useState([]);
  const [subServiceList, setSubServiceList] = useState([]);
  const { id } = useParams();
  const { subid } = useParams();
  const { subserviceid } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.state);
  const paramsData = useParams();
  const [caseStudyList, setCaseStudyList] = useState([]);
  const [sub_CaseStudy, setSub_CaseStudy] = useState([]);
  const [faqItems, setFaqItems] = useState([]);
  const [verified, setVerified] = useState(false);
  const [casestudy, setCasestudy] = useState([]);
  const uuid = searchParams.get("id");
  const service_id = searchParams.get("subid");
  const [seoData, setSeoData] = useState(null);
  const [fullArray, setFullArray] = useState([]);

  if (!id) {
    <div>Loading.....</div>;
  }

  const url = window.location.href;

  useEffect(() => {
    const parts = url.split("/");
    const extractedId1 = parts[4];
    const extractedId2 = parts[5];

    AOS.init();
    const fetchServices = async () => {
      const { data } = await axios.get(
        `https://backend.react.nvds.in/api/service/${extractedId1}/level-2/${extractedId2}`
      );

      // const faqData = data.data.sub_services.faqs;
      // const items = faqData.map((item) => ({
      //   title: item.title,
      //   content: item.description,
      // }));
      // setFaqItems(items);
   
      const seoData = data.data.sub_services;
      setSeoData(seoData);
      const casestudy = data.data.sub_services.casestudy;
      setCasestudy(casestudy);
      setServices({
        name: data.data.sub_services.name,
        content: data.data.sub_services.content,
        description: data.data.sub_services.description,
      });
      setSub_services({
        sub_name: data.data.sub_services.innerservices,
      });
      setFullArray( data.data.sub_services.contents)
    //   let subDetailsDataSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "1"
    //   );
    //   setSubDetailsDataSection(subDetailsDataSection);
    //   let whyAiConsultingSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "2"
    //   );
    //   setWhyAiConsultingSection(whyAiConsultingSection);
    //   let partnerSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "3"
    //   );
    //   setPartnerSection(partnerSection);
    //   let offerSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "4"
    //   );
    //   setOfferSection(offerSection);
    //   let ourApproachSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "5"
    //   );
    //   setOurApproachSection(ourApproachSection);
    //   let whyChooseSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "6"
    //   );
    //   setWhyChooseSection(whyChooseSection);
    //   let ourProcessSection = data.data.sub_services.contents.filter(
    //     (sectionObject) => sectionObject.order === "10"
    //   );
    //   setOurProcessSection(ourProcessSection);
    //   if (offerSection[0] && offerSection[0].extra_contents !== undefined) {
    //     const weOfferExtraContents = offerSection[0].extra_contents;
    //     setWeOfferExtraContents(weOfferExtraContents);
    //   }
    //   if (
    //     ourApproachSection[0] &&
    //     ourApproachSection[0].extra_contents !== undefined
    //   ) {
    //     const ourApproachExtraContents = ourApproachSection[0].extra_contents;
    //     setOurApproachExtraContents(ourApproachExtraContents);
    //   }
    //   if (
    //     whyChooseSection[0] &&
    //     whyChooseSection[0].extra_contents !== undefined
    //   ) {
    //     const whyChooseExtraContents = whyChooseSection[0].extra_contents;
    //     setWhyChooseExtraContents(whyChooseExtraContents);
    //   }
    };
    fetchServices();
    contactDetails();
    setContentLoaded(true);
  }, [uuid, service_id]);

  useEffect(() => {
    setService(paramsData.subservice);
  }, [paramsData]);

  useEffect(() => {
    swapDesign();
  }, [url , fullArray]);

  const contactDetails = async () => {
    try {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/general"
      );

      const contactUs = data.data.general;
      setContactUs(contactUs);
      const allServices = data.data.all_services;
      const subservicesArray = allServices.flatMap(
        (service) => service.subservices
      );
      setSubServiceList(subservicesArray);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm() && verified) {
      setIsLoading(true);
      
      const recaptchaToken = await window.grecaptcha.execute('6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0', { action: 'submit' });

      const data = { name, email, phone, type, service, message, recaptchaToken };

      axios
        .post("https://backend.react.nvds.in/api/form", data)
        .then((response) => {
          event.target.reset();
          const responseMsg = response.data.message;
          if (responseMsg === "Successful") {
            console.log(responseMsg);
            window.location.href = "/thankyou";
          } else {
            console.log(responseMsg);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!verified) {
      isValid = false;
    }
    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }
    if (!email.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!phone.trim()) {
      setPhoneError("Phone is required");
      isValid = false;
    } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone)) {
      setPhoneError("Phone is invalid");
      isValid = false;
    } else {
      setPhoneError("");
    }
    return isValid;
  };

  useEffect(() => {
    AOS.init();
    const caseListApi = async () => {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/section_6_case"
      );
      const caseStudyList = data.data.section_6_case;
      setCaseStudyList(caseStudyList);
    };
    caseListApi();
  }, []);

  const navigate = useNavigate();
  const handleCasestudyClick = (caseStudy) => {
    navigate(
      `/CaseStudiesSubDetails/${caseStudy.service_id_slug}/${caseStudy.sub_service_id_slug}/${caseStudy.case_study_slug}`,
      {}
    );
  };
  const swapDesign = () => {
    if (fullArray.length > 0) {
      const sortedArray = fullArray.sort((a, b) => parseInt(a.order) - parseInt(b.order));
      
      for (const section of sortedArray){ 

              if (section.section === "type1") {
              var y = section
              subDetailsDataSectionArray.push(y);
              setSubDetailsDataSection(subDetailsDataSectionArray);
            } if (section.section === "type2") {
              var y = section
              whyAiConsultingSectionArray.push(y)
              setWhyAiConsultingSection(whyAiConsultingSectionArray);
            } if (section.section === "type3") {
              var y = section
              partnerSectionArray.push(y)
              setPartnerSection(partnerSectionArray)
            } if (section.section === "type4") {
              var y = section
              offerSectionArray.push(y)
              setOfferSection(offerSectionArray)
              setWeOfferExtraContents(section.extra_contents)
            } if (section.section === "type5") {
              var y = section
              ourApproachSectionArray.push(y)
              setOurApproachSection(ourApproachSectionArray)
              setOurApproachExtraContents(section.extra_contents)
            } if (section.section === "type6") {
              var y = section
              whyChooseSectionArray.push(y)
              setWhyChooseSection(whyChooseSectionArray)
              setWhyChooseExtraContents(section.extra_contents)
            // } if (section.section === "type7") {
              // caseListSectionArray.push(y)
              // setCaseListSection(caseListSectionArray)
            } if (section.section === "type8") {
              ourProcessSectionArray.push(y)
              setOurProcessSection(ourProcessSectionArray)
            }
          //   break;
      }
    }
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0";
    script.async = true;
    document.body.appendChild(script);
}, []); 
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0">
    <>
  {seoData && (
    <Helmet key={seoData.id}>
      {seoData.seo_description !== null && (
        <meta name="description" content={seoData.seo_description} />
      )}
      {seoData.seo_keywords !== null && (
        <meta name="keywords" content={seoData.seo_keywords} />
      )}
      {seoData.seo_title !== null && (
        <title>{seoData.seo_title}</title>
      )}
      {seoData.canonical_tag !== null && (
        <link
        rel="canonical"
        href={seoData.canonical_tag}
      />
      )}
      {seoData.schema !== null && (
        <script type="application/ld+json">
         {seoData.schema}
          </script>
      )}
    </Helmet>
  )}
      {subDetailsDataSection.length > 0 ? (
        <>
          {subDetailsDataSection.map((subDetailsDataSection, index) => (
            <section
              className="pagebanner bgc-primary s"
              key={index}
              style={{
                // backgroundImage: `url(${baseUrl + data.image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom right",
              }}
            >
              {subDetailsDataSection.image == null ? (
              ""
            ) : (
              <img
                className="banner-img"
                src={baseUrl + subDetailsDataSection.image}
                alt={subDetailsDataSection.title}
                loading="lazy"
              /> )}
              {subDetailsDataSection.mobile_image == null ? (
                        ""
                      ) : (
            <img
              className="mobile-banner-img"
              src={baseUrl + subDetailsDataSection.mobile_image}
              alt={subDetailsDataSection.title}
              loading="lazy"
            /> )}

              <div className="container">
                <div
                  className="pagebanner-left"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h1 className="text-light">{subDetailsDataSection.title}</h1>
                  <h3 className="text-light">{subDetailsDataSection.sub_title}</h3>
                  <div class="scrollbar-2 h-270 pl-60 ml--60">
                  <p
                    className="text-light" dir="ltr"
                    dangerouslySetInnerHTML={{ __html: subDetailsDataSection.description }}
                  ></p></div>
                  
                  {subDetailsDataSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={subDetailsDataSection.button_link}
                        className="button text-light"
                        onClick={
                          subDetailsDataSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {subDetailsDataSection.button_title} <BsArrowRight /></Link>)}
                </div>
              </div>
              {subDetailsDataSection.image == null ? (
              ""
            ) : (
              <img
                className="mobile-banner"
                src={baseUrl + subDetailsDataSection.image}
                alt={subDetailsDataSection.title}
                loading="lazy"
              /> )}
            </section>
          ))}
        </>
      ) : (
        " "
      )}

{whyAiConsultingSection.length > 0 ? (
        <>
          {whyAiConsultingSection.map((whyAiConsultingSection, index) => (
            <section className="content introduction bg-light" key={index}>
              <div className="container">
                <>
                  <div className="content-full text-center">
                    <h2
                      className="text-primary"
                      dangerouslySetInnerHTML={{ __html: whyAiConsultingSection.title }}
                    ></h2>
                    <div
                      className="text-dark text-center"
                      dangerouslySetInnerHTML={{ __html: whyAiConsultingSection.description }}
                    ></div>
                    {whyAiConsultingSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={whyAiConsultingSection.button_link}
                        className="button text-dark d-block mt-2 fw-bold"
                        onClick={
                          whyAiConsultingSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {whyAiConsultingSection.button_title} <BsArrowRight /></Link>)}
                  </div>
                </>
              </div>
            </section>
          ))}
        </>
      ) : (
        " "
      )}

{partnerSection.length > 0 ? (
        <section className="content our-story">
          <div className="container">
          {partnerSection.map((partnerSection, index) => (
              <>
                <div
                  className="content-left pl-60"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h3 className="text-primary">{partnerSection.title}</h3>
                  <div className="text-dark scrollbar-2 h-270 pl-60 ml--60">
                    <div
                      className="text-start"
                      dir="ltr"
                      dangerouslySetInnerHTML={{ __html: partnerSection.description }}
                    ></div>
                  </div>
                  {/* <div className="scrollbar-2 h-270 pl-60 ml--60"><div className="text-dark" dangerouslySetInnerHTML={{ __html: object.description }}></div></div> */}
                  {partnerSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={partnerSection.button_link}
                        className="button text-dark fw-bold"
                        onClick={
                          partnerSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {partnerSection.button_title} <BsArrowRight /></Link>)}
                </div>
                <div
                  className="content-right"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {partnerSection.image == null ? (
                    ""
                  ) : (
                    <div
                      className="spacer-01"
                      style={{
                        backgroundImage: `url(${baseUrl + partnerSection.image})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                      }}
                    ></div>
                  )}
                </div>
              </>
            ))}
          </div>
        </section>
      ) : (
        ""
      )}

{offerSection.length > 0 ? (
        <section className="content services">
          <div className="container">
          {offerSection.map((offerSection, index) => (
              <div
                className="content-left"
                key={index}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="text-dark">{offerSection.title}</h2>
                <div className="text-dark servic_desc">{offerSection.description}</div>
                {offerSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={offerSection.button_link}
                        className="button text-dark fw-bold d-block mt-2"
                        onClick={
                          offerSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {offerSection.button_title} <BsArrowRight /></Link>)}
              </div>
            ))}
            <div className="content-right">
              <div className="service_sec service-page-service-slider">
                <Slider {...settings}>
                {weOfferExtraContents.map((weOfferExtraContents, i) => (
                    <div className="item" key={i}>
                      {weOfferExtraContents.image == null ? (
              ""
            ) : (
                      <img
                        src={baseUrl + weOfferExtraContents.image}
                        alt="title"
                        className="service-img"
                        width="60"
                        height="60"
                        loading="lazy"
                      /> )}
                      <b className="text-center d-block">
                        {weOfferExtraContents.title}
                      </b>
                      <p className="text-center">{weOfferExtraContents.description}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
{/* OUR PROCESS TOWARDS THIS SERVICE START */}
{ourProcessSection.length > 0 ? (
        <section className="content bgc-primary pb-0">
          <div className="container case-studies service-page-case-studies slider-center-arrow pb-0">
            <div
              className="content-full"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {ourProcessSection.map((ourProcessSection, index) => (
                <div key={index}>
                  <h2 className="text-light w-100 text-center">{ourProcessSection.title}</h2>
                  <p className="text-light w-100 text-center">
                    {ourProcessSection.description}
                  </p>
                  {ourProcessSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={ourProcessSection.button_link}
                        className="button text-light"
                        onClick={
                          ourProcessSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {ourProcessSection.button_title} <BsArrowRight /></Link>)}
                </div>
              ))}
               </div>
          </div>
        </section>
      ) : (
        ""
      )}
{/* OUR PROCESS TOWARDS THIS SERVICE END */}

      {/* OUR APPROACH TOWARDS THIS SERVICE START */}
      {ourApproachSection.length > 0 ? (
        <section className="content bgc-primary">
          <div className="container case-studies service-page-case-studies slider-center-arrow pb-0">
            <div
              className="content-full"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
               {ourApproachSection.map((ourApproachSection, index) => (
                <div key={index}>
                  <h2 className="text-light w-100 text-center">{ourApproachSection.title}</h2>
                  <p className="text-light w-100 text-center">
                    {ourApproachSection.description}
                  </p>
                  {ourApproachSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={ourApproachSection.button_link}
                        className="button text-blue d-block text-center fw-bold"
                        onClick={
                          ourApproachSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {ourApproachSection.button_title} <BsArrowRight /></Link>)}
                </div>
              ))}
              {ourApproachExtraContents.length>0 ? (
              <Slider {...approach_settings}>
              {ourApproachExtraContents.map((ourApproachExtraContents) => (
                  <>
                    <div className="item" key={ourApproachExtraContents.id}>
                      <h4 className="text-light text-center">{ourApproachExtraContents.title}</h4>
                      <p className="text-light text-center">
                        {ourApproachExtraContents.description}
                      </p>
                      {ourApproachExtraContents.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={ourApproachExtraContents.button_link}
                        className="button text-light"
                        onClick={
                          ourApproachExtraContents.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {ourApproachExtraContents.button_title} <BsArrowRight /></Link>)}
                    </div>
                  </>
                ))}
              </Slider>
      ) : (
        ""
      )}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* OUR APPROACH TOWARDS THIS SERVICE END */}

      {whyChooseSection.length > 0 ? (
        <section className="content">
          <div className="container-fluid column-reverse why-us home-whyus">
            <div
              className="content-left"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
               {whyChooseSection.map((whyChooseSection , index) => (
                <div key={index}>
                  <h3 className="text-primary">{whyChooseSection.sub_title}</h3>
                  <h2 className="text-primary">{whyChooseSection.title}</h2>
                  <p
                    className="text-dark text-centers"
                    dangerouslySetInnerHTML={{ __html: whyChooseSection.description }}
                  ></p>
                  {/* {whyChooseSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={whyChooseSection.button_link}
                        className="button text-dark"
                        onClick={
                          whyChooseSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {whyChooseSection.button_title} <BsArrowRight /></Link>)} */}
                </div>
              ))}
            </div>
            <div className="content-right">
              <Slider {...settings}>
              {whyChooseExtraContents.map((whyChooseExtraContents, index) => (
                  <div key={index}>
                    {whyChooseExtraContents.image == null ? (
                      ""
                    ) : (
                      <img
                        className="no-filters"
                        src={baseUrl + whyChooseExtraContents.image}
                        alt="title"
                        width="65"
                        height="65"
                        loading="lazy"
                      />
                    )}
                    <h4 className="text-dark">{whyChooseExtraContents.title}</h4>
                    <p className="text-dark">{whyChooseExtraContents.description}</p>
                    {whyChooseExtraContents.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={whyChooseExtraContents.button_link}
                        className="button text-light"
                        onClick={
                          whyChooseExtraContents.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {whyChooseExtraContents.button_title} <BsArrowRight /></Link>)}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {/* CASE STUDIES START */}
      {casestudy.length > 0 ? (
        <section className="bgc-primary content">
          <div className="container-fluid case-studies service-page-case-studies">
            <div className="content-right">
              <Slider {...caseSettings}>
                {casestudy.map((caseStudy) => (
                  <div className="item" key={caseStudy.id}>
                    <div
                      className="link"
                      onClick={() => handleCasestudyClick(caseStudy)}
                    ></div>
                    <h4 className="text-center text-light">
                      {caseStudy.title}
                    </h4>
                    <p className="text-center text-light">
                      {caseStudy.content}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>

            <div
              className="content-left"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 className="text-light">
                <span>{caseStudyList.title}</span> {caseStudyList.subtitle}
              </h2>
              <p className="text-light">{caseStudyList.content}</p>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {/* CASE STUDIES ENDS */}

      {/* LETS TALK STARTS */}
      {/* ORDER NUMBER 8 */}
      {/* <section className='content bgc-primary'>
          <div className='container'>
              <div className='content-full' data-aos="fade-up" data-aos-duration="1000">
                    <h2 className="text-white text-center">Lets talk title</h2>
                    <p className="text-white text-center mt-3">Lets talk content</p>
                    <a href="#button-link" className="text-center d-block">Lets Talk Button name</a>
              </div>
          </div>
      </section> */}
      {/* LETS TALK ENDS */}

      {/* TESTIMONIALS START */}
      {/* <Testimonials /> */}
      {/* TESTIMONIALS ENDS */}

      {/* {faqItems.length > 0 ? (
      <section className='content' id="enquiry">
        <div className='container service-contact-box'>
          <div className='content-full' data-aos="fade-up" data-aos-duration="1000">
            <h2 className='text-dark text-center'>Frequently Asked Questions</h2>

            <div className="faq-accordion">
              <Accordion items={faqItems} />
            </div>

          </div>
        </div>
      </section> ) : "" } */}

      {/* ENQUIRY FORM START */}
      {subDetailsDataSection.length > 0 ? (
      <section className="content">
        <div className="form-anchor" id="enquiry"></div>
        <div className="container service-contact-box">
          <div
            className="content-left"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-dark">
              Discuss Your Project
              <br />
              With Us Now
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col form__linput">
                  <div className={`form-floating ${name && "active"}`}>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      name="name"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="name">Your Name <span className="text-danger">*</span></label>
                  </div>
                  {nameError && <p className="error">{nameError}</p>}
                </div>
                <div className="col">
                  <div className={`form-floating ${email && "active"}`}>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      name="email"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="email">Email Address <span className="text-danger">*</span></label>
                  </div>
                  {emailError && <p className="error">{emailError}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={`form-floating ${phone && "active"}`}>
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      name="phone"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="phone">Phone Number <span className="text-danger">*</span></label>
                  </div>
                  {phoneError && <p className="error">{phoneError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      id="service"
                      placeholder=" "
                      name="service"
                      value={service}
                      readOnly
                      onChange={(event) => setService(event.target.value)}
                    />
                    <label htmlFor="service">Select Service <span className="text-danger">*</span></label>
                    <ul className="service-list">
                      <li>
                        <span onClick={() => setService("")}>
                          Select Service
                        </span>
                      </li>
                      {service_data &&
                        service_data.map((item) => (
                          <React.Fragment key={item.id}>
                            <li>
                              <span onClick={() => setService(item.slug)}>
                                {item.title}
                              </span>
                              {item.subservices.length > 0 && (
                                <ul>
                                  {item.subservices.map((data) => (
                                    <React.Fragment key={data.id}>
                                      <li>
                                        <span
                                          onClick={() =>
                                            setService(
                                              `${item.slug}-${data.slug}`
                                            )
                                          }
                                        >
                                          {data.name}
                                        </span>
                                        {data.innerservices.length > 0 && (
                                          <ul>
                                            {data.innerservices.map(
                                              (object) => (
                                                <li key={object.id}>
                                                  <span
                                                    onClick={() =>
                                                      setService(
                                                        `${item.slug}-${data.slug}-${object.slug}`
                                                      )
                                                    }
                                                  >
                                                    {object.name}
                                                  </span>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    </React.Fragment>
                                  ))}
                                </ul>
                              )}
                            </li>
                          </React.Fragment>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={`form-floating ${message && "active"}`}>
                    <textarea
                      className="form-control"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      name="message"
                      rows="3"
                      placeholder=" "
                      autoComplete="off"
                    ></textarea>
                    <label htmlFor="message">Your Message (optional)</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                <GoogleReCaptcha
                  sitekey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0"
                  onVerify={() => {
                    console.log("reCAPTCHA verified!");
                    setVerified(true);
                  }}
                />
                </div>
              </div>
              <div className="row">
                <div className="form-group text-left">
                  <button type="submit">
                    {isLoading ? (
                      <>
                      <div className="btn-loader"></div> Sending ...
                      </>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-black">Our Contact Details</h2>
            <div className="contact-box">
              <small className="d-block">Call Us</small>
              <a href={`tel:${String(contactUs.mobile).replace(/\s/g, "")}`}>
                <b>{contactUs.mobile}</b>
              </a>
            </div>
            <div className="contact-box">
              <small className="d-block">Address</small>
              <b dangerouslySetInnerHTML={{ __html: contactUs.address }}></b>
            </div>
            <div className="contact-box">
              <small className="d-block">Email Address</small>
              <a href={`mailto:${String(contactUs.email)}`}>
                <b>{contactUs.email}</b>
              </a>
            </div>
          </div>
        </div>
      </section>
     ) : (
         ""
       )} 
      {/* ENQUIRY FORM END */}
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
    </GoogleReCaptchaProvider>
  );
};
export default SubServiceDetails;
