import React, { useState, useEffect } from "react";
import axios from "axios";
import "../pages/services/services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import "../pages/services/services.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from '../components/Footer';

const CaseStudiesSubDetails = () => {

  var subDetailsDataSectionArray = [];
  var whyAiConsultingSectionArray = [];
  var partnerSectionArray = [];
  var offerSectionArray = [];
  var ourApproachSectionArray = [];
  var whyChooseSectionArray = [];

  const [subDetailsDataSection, setSubDetailsDataSection] = useState([]);
  const [whyAiConsultingSection, setWhyAiConsultingSection] = useState([]);
  const [partnerSection, setPartnerSection] = useState([]);
  const [offerSection, setOfferSection] = useState([]);
  const [ourApproachSection, setOurApproachSection] = useState([]);
  const [whyChooseSection, setWhyChooseSection] = useState([]);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [fullArray, setFullArray] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);

  const url = window.location.href;
  useEffect(() => {
    const parts = url.split("/");
    const extractedId1 = parts[4];
    const extractedId2 = parts[5];
    const extractedId3 = parts[6];

    AOS.init();
    const fetchCaseStudyDetails = async () => {
      const { data } = await axios.get(
        `https://backend.react.nvds.in/api/case-studies/${extractedId1}/level-2/${extractedId2}/${extractedId3}`
      );

      setFullArray( data.data.case_studies[0].contents)

      // if (data && data.data && Array.isArray(data.data.case_studies)) {
      //   const allContents1 = data.data.case_studies.flatMap(
      //     (caseStudy) => caseStudy.contents || []
      //   );
      //   const subDetailsDataSection = allContents1.filter(
      //     (content) => content.order === "1"
      //   );
      //   setSubDetailsDataSection(subDetailsDataSection);

      //   const allContents2 = data.data.case_studies.flatMap(
      //     (caseStudy) => caseStudy.contents || []
      //   );
      //   const whyAiConsultingSection = allContents2.filter(
      //     (content) => content.order === "2"
      //   );
      //   setWhyAiConsultingSection(whyAiConsultingSection);

      //   const allContents3 = data.data.case_studies.flatMap(
      //     (caseStudy) => caseStudy.contents || []
      //   );
      //   const partnerSection = allContents3.filter(
      //     (content) => content.order === "3"
      //   );
      //   setPartnerSection(partnerSection);

      //   const allContents4 = data.data.case_studies.flatMap(
      //     (caseStudy) => caseStudy.contents || []
      //   );
      //   const offerSection = allContents4.filter(
      //     (content) => content.order === "4"
      //   );
      //   setOfferSection(offerSection);

      //   const allContents5 = data.data.case_studies.flatMap(
      //     (caseStudy) => caseStudy.contents || []
      //   );
      //   const ourApproachSection = allContents5.filter(
      //     (content) => content.order === "5"
      //   );
      //   setOurApproachSection(ourApproachSection);

      //   const allContents6 = data.data.case_studies.flatMap(
      //     (caseStudy) => caseStudy.contents || []
      //   );
      //   const whyChooseSection = allContents6.filter(
      //     (content) => content.order === "6"
      //   );
      //   setWhyChooseSection(whyChooseSection);

        // let whyChooseSection = data.data.case_studies.contents.filter((sectionObject) => sectionObject.order === "6");
        // setWhyChooseSection(whyChooseSection);
      // }
    };
    fetchCaseStudyDetails();
    setContentLoaded(true);
  }, [url]);

  useEffect(() => {
    swapDesign();
  }, [url , fullArray]);

  const swapDesign = () => {
    // if (fullArray && Array.isArray(fullArray) && fullArray.length > 0) {
      if (fullArray.length > 0) {
      const sortedArray = fullArray.sort((a, b) => parseInt(a.order) - parseInt(b.order));
      // console.log("fullarrayyyyyy",fullArray);
      for (const section of sortedArray) {
        if (section.section === "type1") {
          var y = section
          subDetailsDataSectionArray.push(y);
          setSubDetailsDataSection(subDetailsDataSectionArray);
        } else if (section.section === "type2") {
          var y = section
          whyAiConsultingSectionArray.push(y)
          setWhyAiConsultingSection(whyAiConsultingSectionArray);
        } else if (section.section === "type3") {
          var y = section
          partnerSectionArray.push(y)
          setPartnerSection(partnerSectionArray)
        } else if (section.section === "type4") {
          var y = section
          offerSectionArray.push(y)
          setOfferSection(offerSectionArray)
        } else if (section.section === "type5") {
          var y = section
          ourApproachSectionArray.push(y)
          setOurApproachSection(ourApproachSectionArray)
        } else if (section.section === "type6") {
          var y = section
          whyChooseSectionArray.push(y)
          setWhyChooseSection(whyChooseSectionArray)
          // } else if (section.section === "type7") {
          // caseListSectionArray.push(y)
          // setCaseListSection(caseListSectionArray)
        }
      }
    }
  }

  return (
    <>
      {/* PAGEBANNER START */}
      {subDetailsDataSection.length > 0 ? (
        <>
          {subDetailsDataSection.map((subDetailsDataSection, index) => (
            <section
              className="pagebanner 2"
              key={index}
              style={{
                backgroundImage: `url(${baseUrl + subDetailsDataSection.image1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
              }}
            >
              <div className="container">
                <div
                  className="pagebanner-left"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h1 className="text-light">{subDetailsDataSection.title}</h1>
                  <div class="scrollbar-2 h-270 pl-60 ml--60">
                  <p
                    className="text-light" dir="ltr"
                    dangerouslySetInnerHTML={{ __html: subDetailsDataSection.content }}
                  ></p></div>
                  {subDetailsDataSection.button_link == null ? (
                        ""
                      ) : (
                        <Link
                        to={subDetailsDataSection.button_link}
                        className="button text-light">
                        {subDetailsDataSection.button_title} <BsArrowRight /></Link>)}
                </div>
              </div>
            </section>
          ))}
        </>
      ) : (
        ""
      )}
      {/* PAGEBANNER END */}

      {/* SERVICE INTRODUCTION START */}
      {whyAiConsultingSection.length > 0 ? (
        <>
          {whyAiConsultingSection.map((whyAiConsultingSection, index) => (
            <section className="content introduction bg-light" key={index}>
              <div className="container">
                <div className="content-full text-center">
                  <h2
                    className="text-dark"
                    dangerouslySetInnerHTML={{ __html: whyAiConsultingSection.title }}
                  ></h2>
                  <p
                    className="text-dar text-center"
                    dangerouslySetInnerHTML={{ __html: whyAiConsultingSection.content }}
                  ></p>
                   {whyAiConsultingSection.button_link == null ? (
                        ""
                      ) : (
                        <Link
                        to={whyAiConsultingSection.button_link}
                        className="button text-light"
                        >
                        {whyAiConsultingSection.button_title} <BsArrowRight /></Link>)}
                </div>
              </div>
            </section>
          ))}
        </>
      ) : (
        " "
      )}
      {/* SERVICE INTRODUCTION END */}

      {/* LEFT CONTENT RIGHT IMAGE START */}
      {partnerSection.length > 0 ? (
        <>
          <section className="content our-story">
            <div className="container">
              {partnerSection.map((partnerSection, index) => (
                <>
                  <div
                    className="content-left pl-60"
                    key={index}
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h2 className="text-primary">{partnerSection.title}</h2>
                    <p
                      className="h-270 pl-60 ml--60 black-scroll"
                      dangerouslySetInnerHTML={{ __html: partnerSection.content }}
                    ></p>
                      {partnerSection.button_link == null ? (
                        ""
                      ) : (
                        <Link
                        to={partnerSection.button_link}
                        className="button text-light"
                        >
                        {partnerSection.button_title} <BsArrowRight /></Link>)}
                  </div>
                  <div
                    className="content-right"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {partnerSection.image1 == null ? (
                      ""
                    ) : (
                      <div
                        className="spacer-01"
                        style={{
                          backgroundImage: `url(${baseUrl + partnerSection.image1})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                      ></div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </section>
        </>
      ) : (
        ""
      )}
      {/* LEFT CONTENT RIGHT IMAGE END */}

      {/* SERVICES OFFERED START */}
      {offerSection.length > 0 ? (
        <>
          <section className="content our-values">
            <div className="container">
              {offerSection.map((offerSection, index) => (
                <>
                  <div
                    className="content-left"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {offerSection.image1 == null ? (
                      ""
                    ) : (
                      <div
                        className="spacer-01"
                        style={{
                          backgroundImage: `url(${baseUrl + offerSection.image1})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                      ></div>
                    )}
                  </div>
                  <div
                    className="content-right"
                    key={index}
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h3 className="text-primary">{offerSection.title}</h3>
                    <p
                      className="text-dark h-270 pl-60 ml--60 black-scroll"
                      dangerouslySetInnerHTML={{ __html: offerSection.content }}
                    ></p>
                     {offerSection.button_link == null ? (
                        ""
                      ) : (
                        <Link
                        to={offerSection.button_link}
                        className="button text-light">
                        {offerSection.button_title} <BsArrowRight /></Link>)}
                  </div>
                </>
              ))}
            </div>
          </section>
        </>
      ) : (
        ""
      )}

      {/* SERVICES OFFERED END */}

      {/* OUR APPROACH TOWARDS THIS SERVICE START */}
      {ourApproachSection.length > 0 ? (
        <section className="content bgc-primary">
          <div className="container industry-features">
            {ourApproachSection.map((ourApproachSection, index) => (
              <>
                <div
                  className="content-full pl-60"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2 className="text-light text-center w-100">
                    {ourApproachSection.title}
                  </h2>
                  <p
                    className="text-light text-center"
                    dangerouslySetInnerHTML={{ __html: ourApproachSection.content }}
                  ></p>
                  {ourApproachSection.button_link == null ? (
                        ""
                      ) : (
                        <Link
                        to={ourApproachSection.button_link}
                        className="button text-light"
                       >
                        {ourApproachSection.button_title} <BsArrowRight /></Link>)}
                </div>
              </>
            ))}
          </div>
        </section>
      ) : (
        ""
      )}

      {/* OUR APPROACH TOWARDS THIS SERVICE END */}

      {/* LEFT CONTENT RIGHT IMAGE START */}
      {whyChooseSection.length > 0 ? (
        <>
          <section className="content our-story">
            <div className="container">
              {whyChooseSection.map((whyChooseSection, index) => (
                <>
                  <div
                    className="content-left pl-60"
                    key={index}
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h3 className="text-primary">{whyChooseSection.title}</h3>
                    <p
                      className="h-270 pl-60 ml--60 black-scroll"
                      dangerouslySetInnerHTML={{ __html: whyChooseSection.content }}
                    ></p>
                    {whyChooseSection.button_link == null ? (
                        ""
                      ) : (
                        <Link
                        to={whyChooseSection.button_link}
                        className="button text-light">
                        {whyChooseSection.button_title} <BsArrowRight /></Link>)}
                  </div>
                  <div
                    className="content-right"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {whyChooseSection.image1 == null ? (
                      ""
                    ) : (
                      <div
                        className="spacer-01"
                        style={{
                          backgroundImage: `url(${baseUrl + whyChooseSection.image1})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                      ></div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </section>
        </>
      ) : (
        ""
      )}
      {/* LEFT CONTENT RIGHT IMAGE END */}
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  );
};
export default CaseStudiesSubDetails;
