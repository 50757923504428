import { Link } from 'react-router-dom'
import './footer.css'
import {BiMap} from 'react-icons/bi'
import {BiPhone} from 'react-icons/bi'
import React, { useState, useEffect } from "react";
import axios from "axios";
import BackToTop from "../components/backToTop";
import CookieConsent from '../components/CookieConsent';
// import Chatbot from '../components/chatBot';
 
const Footer = ({ initiallyHidden }) => {
    const currentYear = new Date().getFullYear();
    const baseUrl = "https://backend.react.nvds.in/storage/";
    const [contactUs, setContactUs] = useState(null);
    const [loading, setLoading] = useState(true);

    // const [GTM_ID , setGTM_ID] = useState([]);
    // const [animationComplete, setAnimationComplete] = useState(false);
 
    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     setAnimationComplete(true);
    //   }, 2);
    //   return () => clearTimeout(timeout);
    // }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
            const { data } = await axios.get('https://backend.react.nvds.in/api/general');
            const contactUs = data.data.general;
            setContactUs(contactUs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, []);
  
    // const gtmSeo = async () => {
    //     const { data } = await axios.get(
    //       `https://backend.react.nvds.in/api/gtm`
    //     );
    //     const GTM_ID = data.data.gtm;
    //     setGTM_ID(GTM_ID)
    //   };

    return (
      <>
     {!loading && contactUs && Object.keys(contactUs).length > 0 && (
    <footer style={{ display: initiallyHidden ? 'none' : 'block' }}>
        <div className={`container`}>
            <div className='row align-items-centers'>
                <div className='col ft-logo'>
                    <Link to='/'> 
                    {contactUs.light_logo == null ? (
                        ""
                      ) : (
                        <img src={baseUrl + contactUs.light_logo} alt='Intellect.Works' width='150' height='66'
                        //  loading="lazy"
                          /> )}
                    </Link>
                </div>
                <div className='col addresss'>
                    <h4>&nbsp;</h4>
                    <ul className='mb-0'>
                        {/* <li><Link to='/about' aria-label='About Us'> About Us </Link></li> */}
                        <li><Link to='/Career' aria-label='Career'> Career </Link></li>
                        {/* <li><Link to='/blogs' aria-label='Blogs'> Blogs </Link></li>
                        <li><Link to='/casestudies' aria-label='Case Studies'> Case Studies </Link></li> */}
                        <li><Link to='/contact' aria-label='Contact Us'> Contact Us </Link></li>
                        <li><Link to='/privacy-policy' aria-label='Learn more about Intellect.Works Privacy Policy'> Privacy Policy </Link></li>
                        {/* <li><Link to='/terms-and-conditions' aria-label='Learn more about Intellect.Works Terms & Conditions'> Terms & Conditions </Link></li>
                        <li><Link to='/cookie-policy' aria-label='Learn more about Intellect.Works Cookie Policy'> Cookie Policy </Link></li> */}
                    </ul>
                </div>
                <div className='col address'>
                    <h4>USA</h4>
                    <div className='address-wrapper'><BiMap /><p dangerouslySetInnerHTML={{ __html: contactUs.address }}></p></div>
                    <div className='address-wrapper'><BiPhone /><p>
                        {/* <Link to={`tel:${String(contactUs.mobile).replace(/\s/g, '')}`} aria-label="Learn more about Intellect.Works">{contactUs.mobile}</Link> */}
                        <a href={`tel:${String(contactUs.mobile).replace(/\s/g, '')}`}>{contactUs.mobile}</a>
                    </p></div>
                </div>
            </div>
        </div>
        <BackToTop />
        {/* <Chatbot /> */}
        <CookieConsent />
        <div className={`container copyright`}>
        {/* <script>{GTM_ID.body}</script> */}
            <div className="col">
                <p>
                    <div>
                        &copy; {currentYear} {contactUs.site_title} - All Rights Reserved.&nbsp;
                        {/* <b>
                            <GoPrimitiveDot/>
                            &nbsp;&nbsp;Digitally Empowered by <a href='https://netventure.in' aria-label='Learn more about NetVenture' target='_blank'  rel="noreferrer">NetVenture</a>&nbsp;&nbsp;
                            <GoPrimitiveDot/>
                        </b> */}
                    </div>
                    <ul>
                    {contactUs.facebook == null ? (
                        ""
                      ) : (
                        <li><Link to={contactUs.facebook} aria-label='Learn more about Intellect.Works on Facebook' target='_blank'><span><img src={baseUrl + contactUs.fb_image} width="20" height="20" alt='Intellect.facebook'/> </span></Link></li>
                      )}
                      {contactUs.twitter == null ? (
                        ""
                      ) : (
                      <li><Link to={contactUs.twitter} aria-label='Learn more about Intellect.Works on Twitter' target='_blank'><span> <img src={baseUrl + contactUs.twt_image} width="20" height="20" alt='Intellect.twitter'/> </span></Link></li>
                      )}  
                      {contactUs.instagram == null ? (
                        ""
                      ) : (
                      <li><Link to={contactUs.instagram} aria-label='Learn more about Intellect.Works on Instagram' target='_blank'><span> <img src={baseUrl + contactUs.ig_image} width="20" height="20" alt='Intellect.instagram'/> </span></Link></li>
                        )}
                        {contactUs.linkdln == null ? (
                        ""
                      ) : (
                      <li><Link to={contactUs.linkdln} aria-label='Learn more about Intellect.Works on Linkedin' target='_blank'><span> <img src={baseUrl + contactUs.ld_image} width="20" height="20" alt='Intellect.linkedIn'/> </span></Link></li>
                      )} 
                      {contactUs.youtube == null ? (
                        ""
                      ) : (
                      <li><Link to={contactUs.youtube} aria-label='Learn more about Intellect.Works on Youtube' target='_blank'><span> <img src={baseUrl + contactUs.yt_image} width="20" height="20" alt='Intellect.youtube'/> </span></Link></li>
                      )} 
                    </ul>
                </p>
            </div>
        </div>
    </footer> )}
    </>
    )
}
export default Footer