import ContactBg from "../../images/career-banner.webp";
import "../contact/contact.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { GoogleReCaptcha , GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Footer from '../../components/Footer';

const Career = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactUs, setContactUs] = useState([]);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [positionJob, setPositionJob] = useState("");
  const [resume, setResume] = useState(null);
  const [resumeError, setResumeError] = useState("");
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState("");
  const [verified, setVerified] = useState(false);
  const [careerPosition, careerPositionData] = useState([]);
  const [mapArray, setMapArray] = useState([]);
  const [selectedPositionDescription, setSelectedPositionDescription] = useState("");
  const [selectedPositionExperience, setSelectedPositionExperience] = useState("");
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [contentLoaded, setContentLoaded] = useState(false);


  useEffect(() => {
    AOS.init();
    const CareerPositionApi = async () => {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/career-openings"
      );
      const careerPosition = data.data.career;
      careerPositionData(careerPosition);
    };
    CareerPositionApi();
  }, []);

  useEffect(() => {
    AOS.init();
    contactDetails();
    fetchPosition();
    setContentLoaded(true);
  }, []);

  const handleResumeChange = (event) => {
    let isValid = true;
    const file = event.target.files[0];
    setResume(file);
    if (event.target.files[0].size > 2000000) {
      setResumeError("PDF size should be less than 2 MB");
      isValid = false;
   }else {
    setResumeError("");
   } 
   }

   const handleSubmit = async (event) => {
    event.preventDefault(); 

    if (validateForm() && verified) {
      setIsLoading(true);

      const recaptchaToken = await window.grecaptcha.execute('6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0', { action: 'submit' });

      const formObject = new FormData();
      formObject.append("name", name);
      formObject.append("email", email);
      formObject.append("phone", phone);
      formObject.append("message", message);
      formObject.append("resume", resume);
      formObject.append("position", position);
      formObject.append("recaptchaToken", recaptchaToken)

      axios
        .post("https://backend.react.nvds.in/api/career-form", formObject)
        .then((response) => {
          event.target.reset();
          const responseMsg = response.data.message;
          if (responseMsg === "Successful") {
            console.log(responseMsg);
            window.location.href = "/thankyou";
          } else {
            console.log(responseMsg);
          }
          // console.log("dataaaa", formObject);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!verified) {
      isValid = false;
    }
    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }
    if (!email.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!phone.trim()) {
      setPhoneError("Phone is required");
      isValid = false;
    } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone)) {
      setPhoneError("Phone is invalid");
      isValid = false;
    } else {
      setPhoneError("");
    }
    if (!resume) {
      setResumeError("Resume file is required");
      isValid = false;
    } else if (!/\.pdf$/.test(resume.name.toLowerCase())) {
      setResumeError("Please upload a PDF file");
      isValid = false;
    } else {
      setResumeError("");
    }
    if (!message.trim()) {
      setMessageError("Message is required");
      isValid = false;
    } else {
      setMessageError("");
    }
    if (!position.trim()) {
      setPositionError("Position is required");
      isValid = false;
    } else {
      setPositionError("");
    }
    return isValid;
  };

  const contactDetails = async () => {
    try {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/general"
      );

      // const contactUs = data.data.general;
      // setContactUs(contactUs);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPosition = async () => {
    try {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/career-openings"
      );
      const positionJob = data.data.career;
      setPositionJob(positionJob);
      const mapArray = data.data.career_settings
      setMapArray(mapArray);
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://www.google.com/recaptcha/api.js?render=6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0";
  //   script.async = true;
  //   script.onload = () => {
  //     AOS.init();
  //   };
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }
  // , []); 
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0">
    <>
    {Array.isArray(positionJob) &&
                      positionJob.map((positionItem) => (
    <Helmet>
      {positionItem.seo_description !== null && (
        <meta name="description" content={positionItem.seo_description} />
      )}  
      {positionItem.seo_keywords !== null && (
         <meta name="keywords" content={positionItem.seo_keywords}/>
      )} 
      {positionItem.seo_title !== null && (
          <title>{positionItem.seo_title}</title>
      )} 
      {positionItem.canonical_tag !== null && (
        <link
        rel="canonical"
        href={positionItem.canonical_tag}
      />
      )}
      {positionItem.schema !== null && (
        <script type="application/ld+json">
         {positionItem.schema}
          </script>
      )}
    </Helmet>
          ))} 

      <section
        className="pagebanner bgc-primary"
        style={{
          // backgroundImage: `url(${ContactBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          // minHeight: "80vh",
        }}
      >
        <img
              className="banner-img"
              src={baseUrl + mapArray.banner_image}
              alt="title"
              loading="lazy"
            />
            <img
                  className="mobile-banner-img"
                  src={baseUrl + mapArray.mobile_image}
                  alt="title"
                  loading="lazy"
                />
        <div className="container">
          <div className="pagebanner-left">
            <h1 className="text-light">{mapArray.title}</h1>
            {/* <p className="text-light">lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum </p> */}
            {/* <Link to="/contact" className="button text-light"> Enquire Now <BsArrowRight /> </Link> */}
          </div>
        </div>
        {/* <img className='mobile-banner' src={ContactBg} alt='lorem ipsum' /> */}
      </section>

      <section className="content contact">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="content-left">
              <h2 className="text-black mb-4">{mapArray.sub_title}</h2>
              <div className="row m-0">
                <div className="col form-check-wrapper px-0">
                  <label className="enq-reason col-form-label col-form-label-md">
                    Select Position <span className="text-danger">*</span>
                  </label>
                  <div className="form-check">
  {Array.isArray(positionJob) &&
    positionJob.map((positionItem) => 
    (
      <div key={positionItem.uuid}>
        <input
          className="form-check-input"
          type="radio"
          name="position"
          id={positionItem.uuid}
          value={positionItem.position}
          checked={position === positionItem.position}
          onChange={(event) => {
            setPosition(event.target.value);
            setSelectedPositionDescription(positionItem.description);
            setSelectedPositionExperience(positionItem.experience);
          }}
        />
        <label
          className="form-check-label col-form-label-sm"
          htmlFor={positionItem.uuid}
        >
          {positionItem.position}
        </label>
        {position === positionItem.position && (
          <div className="position-description mb-3">
            <small className="my-0"><strong>Experience : </strong>{selectedPositionExperience}</small><br/>
            <small className="my-0"><strong>Job Description : </strong>{selectedPositionDescription}</small>
          </div>
        )}
      </div>
    ))}
</div>
                  {positionError && <p className="error">{positionError}</p>}
                </div>
              </div>
              
            </div>
            <div className="content-right">
              <div className="row">
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      name="name"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                  </div>
                  {nameError && <p className="error">{nameError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      name="email"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="email">
                      Email Address <span className="text-danger">*</span>
                    </label>
                  </div>
                  {emailError && <p className="error">{emailError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      name="phone"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="phone">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                  </div>
                  {phoneError && <p className="error">{phoneError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="file"
                      className="form-control border-0"
                      onChange={handleResumeChange}
                      name="resume"
                    />
                    <label htmlFor="resume">
                      Upload Resume <span className="text-danger">*</span> ( PDF
                      only with maximum uploading size: 2MB )
                    </label>
                  </div>
                  {resumeError && <p className="error">{resumeError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      name="message"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="message">
                      Your Message <span className="text-danger">*</span>&nbsp;&nbsp;
                    </label>
                  </div>
                  {messageError && <p className="error">{messageError}</p>}
                </div>
                <div className="col mb-3">
                <GoogleReCaptcha
                  sitekey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0"
                  onVerify={() => {
                    console.log("reCAPTCHA verified!");
                    setVerified(true);
                  }}
                />
                </div>
                <div className="form-group text-left">
                  <button type="submit">
                    {isLoading ? (
                      <>
                      <div className="btn-loader"></div> Sending ...
                      </>
                    ) : (
                      "Submit Now"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
    </GoogleReCaptchaProvider>
  );
};

export default Career;
