import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation  } from 'react-router-dom'
import Home from './pages/home/Home'
import About from './pages/about/About'
// import Services from './pages/services/Services'
// import Industries from './pages/industries/Industries'
// import Gallery from './pages/gallery/Gallery'
import Contact from './pages/contact/Contact'
import Career from './pages/career/Career'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
// import SiteRoute from './pages/siteRoute/SiteRoute';
// import { withFallback } from 'react-async';
import ThankYou from './pages/thankYou/ThankYou'
import Services from './components/Services';
import IndustryDetails from './components/IndustryDetails';
import SubServiceDetails from './components/SubServiceDetails';
import Blogs from './components/Blogs';
import BlogPost from './components/BlogPost';
import CaseStudies from './components/CaseStudies';
import Testimonials from './components/Testimonials';
import CaseStudiesDetails from './components/CaseStudiesDetails';
import CaseStudiesSubDetails from './components/CaseStudiesSubDetails';
import CaseStudiesSubDetailsListing from './components/CaseStudiesSubDetailsListing';
import SubServiceListing from './components/SubServiceListing'; 
// import FormPage from './components/formPage';
// import ServiceDemo from './components/serviceDemo';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';
import CookiePolicy from './pages/cookiePolicy/CookiePolicy';
import NotFound from './pages/notFound/NotFound'
import './script.js'

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
};

const App = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Navbar />
            <Routes>
                <Route index element={<Home/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/services' element={<Services/>} />
                {/* <Route path='/industries' element={<Industries/>} /> */}
                {/* <Route path='/gallery' element={<Gallery/>} /> */}
                <Route path='/contact' element={<Contact/>} />
                <Route path='/career' element={<Career/>} />
                <Route path='*' element={<NotFound/>} />
                <Route path='/blogs' element={<Blogs/>} />
                <Route path='/thankyou' element={<ThankYou/>} />
                <Route path={`/casestudiesdetails/:name/:case_study_slug`} element={<CaseStudiesDetails/>} />
                <Route path={`/CaseStudiesSubDetails/:name/:subservice/:case_study_slug`} element={<CaseStudiesSubDetails/>} />
                <Route path={`/CaseStudiesSubDetailsListing/:name/:subservice/:innerservice/:case_study_slug`} element={<CaseStudiesSubDetailsListing/>} />
                {/* <Route path='/siteroute' element={<SiteRoute/>} /> */}
                {/* <Route path='/Testimonials' element={<Testimonials/>} /> */}

                {/* <Route path="/blogPost" element={<BlogPost />}/>         */}
                <Route path='/Services/:name' element={<Services/>} />
                <Route path='/industries/:name' element={<IndustryDetails/>} />
                {/* <Route path={`/services/:name`} element={<ServiceDetails/>} /> */}
                <Route path={`/services/:name/:subservice`} element={<SubServiceDetails />} />
                <Route path={`/services/:name/:subservice/:innerservice`} element={<SubServiceListing/>} />
                <Route path={"/blogs/:slug"} element={<BlogPost/>} />
                <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
                <Route path='/cookie-policy' element={<CookiePolicy/>} />
                {/* <Route path='/thank-you' element={<ThankYou/>} /> */}
                <Route path='/casestudies' element={<CaseStudies/>} />

            </Routes>
            <Testimonials initiallyHidden={true} />
            <Footer initiallyHidden={true} />
            <ScrollToTop />
        </BrowserRouter>
    )
}

export default App