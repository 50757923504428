import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/Footer';

const CookiePolicy = () => {

    const [cookiePolicy, CookiePolicyData] = useState([]);
    const [contentLoaded, setContentLoaded] = useState(false);
    const baseUrl = "https://backend.react.nvds.in/storage/";

    useEffect(() => {
        AOS.init();
        const whyUsApi = async () => {
            const { data } = await axios.get(
                "https://backend.react.nvds.in/api/cookie"
            );
            const cookiePolicy = data.data.cookie
            CookiePolicyData(cookiePolicy);
        };
        whyUsApi();
        setContentLoaded(true);
    }, []);

  return (
    <>
        <section className="pagebanner bgc-primary"
            style={{
                minHeight: "30vh",
            }}>
                 {cookiePolicy.image == null ? (
                          ""
                        ) : (
                <img className="mobile-banner-img" 
                src={baseUrl + cookiePolicy.image} 
                alt="title" 
                loading="lazy" /> )}
            <div className="container">
                <div className="pagebanner-left w-100">
                    <h1 className="text-dark">{cookiePolicy.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: cookiePolicy.content }}></div>
                </div>
            </div>
        </section>
        {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  )
}

export default CookiePolicy
