import React, { useState, useEffect } from "react";
import axios from "axios";
import "./about.css";
import Testimonials from "../../components/Testimonials";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Footer from '../../components/Footer';

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-prev-arrow" onClick={onClick}>
    <IoIosArrowBack />
  </div>
);
const CustomNextArrow = ({ onClick }) => (
  <div className="custom-next-arrow" onClick={onClick}>
    <IoIosArrowForward />
  </div>
);
const About = () => {
  const [about, setAbout] = useState([]);
  const [vision, setVision] = useState([]);
  const [mission, setMission] = useState([]);
  const [aboutSlider, setAboutSlider] = useState([]);
  const [nearSlider, setNearSlider] = useState([]);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [contentLoaded, setContentLoaded] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    margin: "50px",
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    AOS.init();
    const aboutApi = async () => {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/about-us"
      );
      const about = data.data.about;
      setAbout(about);
      const vision = data.data.mission_vision[0];
      setVision(vision);
      const mission = data.data.mission_vision[1];
      setMission(mission);
      const aboutSlider = data.data.about_list;
      setAboutSlider(aboutSlider);
      const nearSlider = data.data.mission_vision[2];
      setNearSlider(nearSlider);
    };
    aboutApi();
    setContentLoaded(true);
  }, []);
  return (
    <>
    <Helmet>
    {about.seo_description !== null && (
    <meta name="description" content={about.seo_description}/>)}
    {about.seo_keywords !== null && (
    <meta name="keywords" content={about.seo_keywords}/>)}
    {about.seo_title !== null && (
    <title>{about.seo_title}</title>)}
     {about.canonical_tag !== null && (
    <link
                rel="canonical"
                href={about.canonical_tag}
              />
      )}
       {about.schema !== null && (
        <script type="application/ld+json">
         {about.schema}
          </script>
      )}
      
    </Helmet>

    {about && about.cover_title && about.cover_content && about.content && about.banner_image && (
      <section className={`bgc-primary content our-story about-banner ${animationComplete ? "animate" : ""}`}>
        <div className="container">
          <div
            className="content-left pl-60"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-light" dangerouslySetInnerHTML={{ __html: about.cover_title }}></h2>
            <h3 className="text-light"dangerouslySetInnerHTML={{ __html: about.cover_content }}></h3>
            <div className="scrollbar-3 h-270 pl-60 ml--60">
              <div
                className="text-light"
                dangerouslySetInnerHTML={{ __html: about.content }}
              ></div>
            </div>
          </div>
          <div
            className={`content-right ${animationComplete ? "animate" : ""}`}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {about.banner_image == null ? (
                      ""
                    ) : (
            <img
              src={baseUrl + about.banner_image}
              alt="our story"
              className="w-100 pl-60"
              loading="lazy"
            /> )}
          </div>
        </div>
      </section>)}
      {vision && vision.title && vision.description && vision.image && (
      <section className={`content our-story ${animationComplete ? "animate" : ""}`}>
        <div className="container">
          <div
            className="content-left pl-60"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-primary">{vision.title}</h2>
            <div
              className="text-dark"
              dangerouslySetInnerHTML={{ __html: vision.description }}
            ></div>
</div>
<div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="spacer-01"
              style={{
                backgroundImage: `url(${baseUrl + vision.image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
</div>
      </section>  )}
  

            {/* <div className="spacer200"></div> */}
            {mission && mission.title && mission.description && mission.image && (
            <section className={`content our-story ${animationComplete ? "animate" : ""}`}>
        <div className="container">
          <div
            className="content-left pl-60"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-primary">{mission.title}</h2>
            <div
              className="text-dark"
              dangerouslySetInnerHTML={{ __html: mission.description }}
            ></div>
          </div>
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="spacer-01"
              style={{
                backgroundImage: `url(${baseUrl + mission.image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
        </div>
      </section>
      )}
         {aboutSlider && aboutSlider.length > 0 && (
      <section className={`content driven-by corevalues bgc-primary ${animationComplete ? "animate" : ""}`}>
        <div className="container">
          <div className="content-left">
            <Slider {...settings}>
              {aboutSlider.map((list, index) => (
                <div key={index}>
                  {list.icon == null ? (
                      ""
                    ) : (
                  <img
                    className="img-invert"
                    src={baseUrl + list.icon}
                    width="68"
                    height="68"
                    alt={list.title}
                    loading="lazy"
                  /> )}
                  <h4 className="text-light text-center">{list.title}</h4>{" "}
                  <p
                    className="text-light text-center"
                    dangerouslySetInnerHTML={{ __html: list.content }}
                  ></p>
                </div>
              ))}
            </Slider>
          </div>  
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {" "}
            <h3
              className="text-light"
              dangerouslySetInnerHTML={{ __html: nearSlider.title }}
            ></h3>
            <div className="">
              <div className="text-light">
                To strive for excellence and emerge as the most trusted global
                AI & ML-powered software development company and lay standards
                that are looked up to, across the industry.
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
      <Testimonials />
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  );
};
export default About;