import ThankYouBg from "../../images/intellect-works-contact-us-banner.webp";
import './thankyou.css';
import Footer from '../../components/Footer';
import React, { useState, useEffect } from "react";

const ThankYou = () => {
    const [contentLoaded, setContentLoaded] = useState(false);
    useEffect(() => {
        setContentLoaded(true);
    }, []);
    return (
        <>
        <section className="pagebanner" 
        style={{
        //   backgroundImage: `url(${ThankYouBg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom right",
        }}>
              <img
              className="banner-img"
              src={ThankYouBg}
              alt="title"
              loading="lazy"
            />
            <div className="container">
                <div className="pagebanner-left">
                    <h1 className="text-light">Thank You</h1>
                    <p  className="text-light">Thank you for contacting us. <br />We will get in touch with you soon...</p>
                </div>
            </div>
        </section>
        {contentLoaded && <Footer initiallyHidden={false} />}
        </>
    )
}

export default ThankYou;