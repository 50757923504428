import React, { useState } from 'react';

function AccordionItem({ title, content, isOpen, toggleAccordion }) {
    const contentClassName = isOpen ? 'accordion-content open' : 'accordion-content';
  const headerClassName = isOpen ? 'accordion-header active' : 'accordion-header';

  return (
    <div className="accordion-item">
      <button className={headerClassName} onClick={toggleAccordion}>
        {title}
      </button>
      {isOpen && (
        <div className={contentClassName}>
          {content}
        </div>
      )}
    </div>
  );
}

function Accordion({ items }) {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (openItemIndex === index) {
      // Clicked on an already open item, so close it
      setOpenItemIndex(null);
    } else {
      // Clicked on a different item, open it and close the previously open one
      setOpenItemIndex(index);
    }
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={index === openItemIndex}
          toggleAccordion={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
}

export default Accordion;
