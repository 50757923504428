import React, { useState, useEffect } from "react";
import { BsArrowBarUp } from 'react-icons/bs';

const BackToTop = () => {
const [showScroll, setShowScroll] = useState(false);

useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

    return (
 <div>
    {showScroll && (
      <div className="scroll-to-top" onClick={scrollToTop}>
        <BsArrowBarUp className="icon" />
      </div>
    )}
  </div>
    )
}
  export default BackToTop;