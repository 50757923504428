import NotFoundBg from "../../images/notfound.webp";
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import './notFound.css'

const NotFound = () => {
  return (    
    <>
    <section className="pagebanner bgc-primary" 
    style={{
      backgroundImage: `url(${NotFoundBg})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right",
    }}>
      <img
              className="banner-img"
              src={NotFoundBg}
              alt="title"
              loading="lazy"
            /> 
        <div className="container">
            <div className="pagebanner-left">
                <h1 className="text-light">404 - Page Not Found</h1>
                <p  className="text-light">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
                <Link to='/' className='link text-light'>Back to Home <BsArrowRight /></Link>
            </div>
        </div>
    </section>
    </>
  )
}

export default NotFound