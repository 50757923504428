import america from "../../images/america.webp";
import ContactForm from "../../images/contact.webp";
// import ContactBg from "../../images/contact-us-banner.webp";
import { Link } from "react-router-dom";
import "./contact.css";
import { BsGeoAlt } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
// import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptcha , GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Footer from '../../components/Footer';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactUs, setContactUs] = useState([]);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [job, setJob] = useState("");
  const [jobError, setJobError] = useState("");
  const [refer, setRefer] = useState("");
  const [referError, setReferError] = useState("");
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [verified, setVerified] = useState(false);
  const [seo, setSeo] = useState("");
  const [contactMain, setContactMain] = useState("");
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [animationComplete, setAnimationComplete] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);


  useEffect(() => {
    AOS.init();
    contactDetails();
    contactSeo();
    setContentLoaded(true);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const contactSeo = async () => {
    const { data } = await axios.get(
      "https://backend.react.nvds.in/api/contact"
    );
    const seo = data.data.contact;
    setSeo(seo)
    const contactMain = data.data.contact[0]
    setContactMain(contactMain)
}
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm() && verified) {
      setIsLoading(true);

      const recaptchaToken = await window.grecaptcha.execute('6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0', { action: 'submit' });
      
      const data = {
        name,
        email,
        phone,
        organization,
        job,
        message,
        refer,
        reason,
        recaptchaToken,
      };

      axios
  .post("https://backend.react.nvds.in/api/contact-form", data)
  .then((response) => {
    event.target.reset();
    const responseMsg = response.data.message;
    
    if (responseMsg === "Successful") {
      console.log(responseMsg);
      window.location.href = "/thankyou";
    } else {
      console.log(responseMsg);
    }
  })
  .catch((error) => {
    console.log(error);
  })
  .finally(() => {
    setIsLoading(false);
  });
}}

  const validateForm = () => {
    let isValid = true;

    if (!verified) {
      isValid = false;
    }
    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }
    if (!organization.trim()) {
      setOrganizationError("Organization is required");
      isValid = false;
    } else {
      setOrganizationError("");
    }
    if (!job.trim()) {
      setJobError("Job Title is required");
      isValid = false;
    } else {
      setJobError("");
    }
    if (!email.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!phone.trim()) {
      setPhoneError("Phone is required");
      isValid = false;
    } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone)) {
      setPhoneError("Phone is invalid");
      isValid = false;
    } else {
      setPhoneError("");
    }
    if (!refer.trim()) {
      setReferError("How heard? is required");
      isValid = false;
    } else {
      setReferError("");
    }
    if (!message.trim()) {
      setMessageError("Message is required");
      isValid = false;
    } else {
      setMessageError("");
    }
    if (!reason.trim()) {
      setReasonError("Reason is required");
      isValid = false;
    } else {
      setReasonError("");
    }
    return isValid;
  };

  const contactDetails = async () => {
    try {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/general"
      );

      const contactUs = data.data.general;
      setContactUs(contactUs);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0";
    script.async = true;
    document.body.appendChild(script);
}, []); 

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0">
    <>
    {Array.isArray(seo) &&
                      seo.map((contact) => (
    <Helmet>
      {contact.seo_description !== null && (
        <meta name="description" content={contact.seo_description} />
      )}  
      {contact.seo_keywords !== null && (
         <meta name="keywords" content={contact.seo_keywords}/>
      )} 
      {contact.seo_title !== null && (
          <title>{contact.seo_title}</title>
      )} 
       {contact.canonical_tag !== null && (
        <link
        rel="canonical"
        href={contact.canonical_tag}
      />
      )}
       {contact.schema !== null && (
        <script type="application/ld+json">
         {contact.schema}
          </script>
      )}
      {/* <script src="https://www.google.com/recaptcha/api.js?render=6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0"></script> */}
    </Helmet>))}
    
      <section
        className={`pagebanner bgc-primary ${animationComplete ? "animate" : ""}`}
        style={{
          // backgroundImage: `url(${ContactBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          // minHeight: "80vh",
        }}
      >
         {contactMain.image == null ? (
                      ""
                    ) : (
         <img
              className="banner-img"
              src={baseUrl + contactMain.image}
              alt="banner"
              loading="lazy"
            /> )}
            {contactMain.mobile_image == null ? (
                         ""
                       ) : (
            <img
                  className="mobile-banner-img"
                  src={baseUrl + contactMain.mobile_image}
                  alt="title"
                  loading="lazy"
                /> )}
        <div className={`container ${animationComplete ? "animate" : ""}`}>
          <div className="pagebanner-left">
            <h1 className="text-light">{contactMain.title}</h1>
            {/* <p className="text-light">lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum </p> */}
            {/* <Link to="/contact" className="button text-light"> Enquire Now <BsArrowRight /> </Link> */}
          </div>
        </div>
        {/* <img className='mobile-banner' src={ContactBg} alt='lorem ipsum' /> */}
      </section>
      
      <section className={`content contact ${animationComplete ? "animate" : ""}`}>
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="content-left">
              <h2 className="text-black">{contactMain.description}</h2>
              <div className="row m-0">
                <div className="col form-check-wrapper pl-0">
                  <label className="enq-reason col-form-label col-form-label-md">
                    Select Reason <span className="text-danger">*</span>
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason1"
                      value="Business Inquiry"
                      checked={reason === "Business Inquiry"}
                      onChange={() => setReason("Business Inquiry")}
                    />
                    <label
                      className="form-check-label col-form-label-sm"
                      htmlFor="Business Inquiry"
                    >
                      Business Inquiry
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason2"
                      value="Alliances & Partnerships"
                      checked={reason === "Alliances & Partnerships"}
                      onChange={() => setReason("Alliances & Partnerships")}
                    />
                    <label
                      className="form-check-label col-form-label-sm"
                      htmlFor="Alliances & Partnerships"
                    >
                      Alliances & Partnerships
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason3"
                      value="Analysts & Media Relationships"
                      checked={reason === "Analysts & Media Relationships"}
                      onChange={() => setReason("Analysts & Media Relationships")}
                    />
                    <label
                      className="form-check-label col-form-label-sm"
                      htmlFor="Analysts & Media Relationships"
                    >
                      Analysts & Media Relationships
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reason4"
                      value="Career Enquiry"
                      checked={reason === "Career Enquiry"}
                      onChange={() => setReason("Career Enquiry")}
                    />
                    <label
                      className="form-check-label col-form-label-sm"
                      htmlFor="Career Enquiry"
                    >
                      Career Enquiry
                    </label>
                  </div>
                  {reasonError && <p className="error">{reasonError}</p>}
                  <div
                    className={`spacer-contact ${animationComplete ? "animate" : ""}`}
                    style={{
                      backgroundImage: `url(${ContactForm})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className={`content-right ${animationComplete ? "animate" : ""}`}>
              <div className="row">
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      name="name"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                  </div>
                  {nameError && <p className="error">{nameError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="organization"
                      value={organization}
                      onChange={(event) => setOrganization(event.target.value)}
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="organization">
                      Organization <span className="text-danger">*</span>
                    </label>
                  </div>
                  {organizationError && (
                    <p className="error">{organizationError}</p>
                  )}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="job"
                      value={job}
                      onChange={(event) => setJob(event.target.value)}
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="jobtitle">
                      Job Title <span className="text-danger">*</span>
                    </label>
                  </div>
                  {jobError && <p className="error">{jobError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      name="email"
                      placeholder=" " 
                      autoComplete="off"
                    />
                    <label htmlFor="email">
                      Email Address <span className="text-danger">*</span>
                    </label>
                  </div>
                  {emailError && <p className="error">{emailError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      name="phone"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="phone">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                  </div>
                  {phoneError && <p className="error">{phoneError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="refer"
                      value={refer}
                      onChange={(event) => setRefer(event.target.value)}
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="howheard">
                      How did you hear about us?{" "}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  {referError && <p className="error">{referError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      name="message"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="message">
                      Your Message&nbsp;&nbsp;
                      {/* <span className="text-danger">*</span> */}
                    </label>
                  </div>
                  {messageError && <p className="error">{messageError}</p>}
                </div>
                <div className="col mb-3">
                <GoogleReCaptcha
  sitekey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0"
  onVerify={() => {
    console.log("reCAPTCHA verified!");
    setVerified(true);
  }}
/>

                </div>
                <div className="col">
                  <p className="information">
                    *By submitting your details, you are agreeing to receive
                    communications about Fractal resources, events, products, or
                    services.
                  </p>
                  <p className="information">
                    By submitting this form you are acknowledging that you have
                    read and agree to our{" "}
                    <a href="/privacy-policy" target="_blank">
                      privacy statement
                    </a>
                    .
                  </p>
                </div>
                <div className="form-group text-left">
                  <button type="submit">
                    {isLoading ? (
                      <>
                      <div className="btn-loader"></div> Sending ...
                      </>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <section className="content pt-0 pb-2">
        <div className="container">
          <div className="content-full">
            <h2>Our office</h2>
          </div>
        </div>
      </section>
      
      <section className={`location-wrapper ${animationComplete ? "animate" : ""}`}>
        <div className="container">
          <div
            className="d-flex align-items-start bgc-primary"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="nav flex-column nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button>United States of America</button>
            </div>
            <div
              className={`tab-content ${animationComplete ? "animate" : ""}`}
              style={{
                backgroundImage: `url(${america})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
              }}
            >
              <div className="text-dark tab-pane fade show active">
                <b dangerouslySetInnerHTML={{ __html: contactUs.address }}></b>
                <br />
                <Link to={`tel:${String(contactUs.mobile).replace(/\s/g, "")}`}>
                  <b>{contactUs.mobile}</b>
                </Link>
                <br />
                <Link to={`mailto:${String(contactUs.email)}`}>
                  <b>{contactUs.email}</b>
                </Link>
                <br />
                <br />
                <a href="https://www.google.com/maps/place/5857+Owens+Ave+%23300,+Carlsbad,+CA+92008,+USA/data=!4m2!3m1!1s0x80dc7343a16afd05:0xdc7cf3f44c176b59?sa=X&ved=2ahUKEwjnnoH67u2BAxVQVmwGHXUBCw8Q8gF6BAgREAA&ved=2ahUKEwjnnoH67u2BAxVQVmwGHXUBCw8Q8gF6BAgSEAI" target="_blank">
                  <BsGeoAlt /> Get Direction
                </a>
                {/* <Link to={contactUs.map_link} className="button" target="_blank" >{" "}
                    {contactUs.button_title}
                  <BsGeoAlt /> Get Direction
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
    </GoogleReCaptchaProvider>
  );
};
export default Contact;
