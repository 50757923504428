import React, { useState, useEffect } from "react";
import quote from "../images/quote.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import axios from "axios";
import { IoIosArrowForward , IoIosArrowBack } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-prev-arrow" onClick={onClick}>
    <IoIosArrowBack />
  </div>
);
const CustomNextArrow = ({ onClick }) => (
  <div className="custom-next-arrow" onClick={onClick}>
    <IoIosArrowForward />
  </div>
);

const Testimonials = ({ initiallyHidden } ) => {
  const [testimonialsMain, setTestimonialsMain] = useState([]);
  const [testimonialSettings, setTestimonialSettings] = useState([]);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [animationComplete, setAnimationComplete] = useState(false);
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    swipe: true,
  };
  useEffect(() => {
    AOS.init();
    const testimonialsApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.react.nvds.in/api/home"
        );

        const testimonialsMainData = data.data.testimonials;
        const testimonialSettingsData = data.data["testimonial-settings"];

        if (Array.isArray(testimonialSettingsData) && testimonialSettingsData.length > 0) {
          setTestimonialSettings([testimonialSettingsData[0]]);
        }
        setTestimonialsMain(testimonialsMainData);
      } catch (error) {
        console.error("Error fetching testimonials data:", error);
      }

      const timeout = setTimeout(() => {
        setAnimationComplete(true);
      }, 10);

      return () => clearTimeout(timeout);
    };

    testimonialsApi();
  }, []);
  return (
    <div style={{ display: initiallyHidden ? 'none' : 'block' }}>
      <section className={`content testimonials ${animationComplete ? "animate" : ""}`}>
        <div className={`container column-center ${animationComplete ? "animate" : ""}`}>
          <div
            className="content-full"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {testimonialSettings.map((data,index) => (

              <div key={data.index}>
                 {data.logo == null ? (
                      ""
                    ) : (
                <img src={baseUrl + data.logo} width="40" height="33" alt="Testimonials" loading="lazy" />)}
                <h2>{data.title}</h2>
              </div>
            ))}
            <Slider {...settings}>
              {testimonialsMain.map((data, index) => (
                <div key={index}>
                  <p className="testimonial-data text-dark text-center">
                    {data.description}
                  </p>
                  {data.image == null ? (
                      ""
                    ) : (
                  <div
                    className="iconbox"
                    style={{
                      backgroundImage: `url(${baseUrl + data.image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  ></div> )}
                  <p className="testimonial-name text-dark text-center" dangerouslySetInnerHTML={{ __html: data.title }}>
                  </p>
                  <p className="testimonial-position text-dark text-center" dangerouslySetInnerHTML={{ __html: data.position }}>
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Testimonials;
