import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import "../pages/services/services.css";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import "../pages/industries/industries.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { GoogleReCaptcha , GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Footer from '../components/Footer';

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-prev-arrow" onClick={onClick}>
    <IoIosArrowBack />
  </div>
);
const CustomNextArrow = ({ onClick }) => (
  <div className="custom-next-arrow" onClick={onClick}>
    <IoIosArrowForward />
  </div>
);
const scrollToEnquiry = () => {
  const enquirySection = document.getElementById("enquiry");
  if (enquirySection) {
    enquirySection.scrollIntoView({ behavior: "smooth" });
  }
};

const IndustryDetails = () => {
  
  var settings_IntroductionSection = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    loop: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };
  var settings_RetailSoftwareSection = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    loop: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings_RetailManagementSection = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    loop: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings_CustomerSection = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    loop: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings_FinalSection = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    loop: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };
  const [industrydata, setIndustries] = useState({
    name: "",
    content: [],
    description: "",
  });

  var bannerSectionArray = [];
  var introductionSectionArray = [];
  var retailSoftwareSectionArray = [];
  var retailManagementSectionArray = [];
  var customerSectionArray = [];
  var finalSectionArray = [];
  var letsTalkSectionArray = [];

  const [isLoading, setIsLoading] = useState(false);
  const [bannerSection, setBannerSection] = useState([]);
  const [introductionSection, setIntroductionSection] = useState([]);
  const [introductionData, setIntroductionData] = useState([]);
  const [retailSoftwareSection, setRetailSoftwareSection] = useState([]);
  const [retailManagementSection, setRetailManagementSection] = useState([]);
  const [customerSection, setCustomerSection] = useState([]);
  const [finalSection, setFinalSection] = useState([]);
  const [retailSoftwareData, setRetailSoftwareData] = useState([]);
  const [retailManagementData, setRetailManagementData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [letsTalkSection, setLetsTalkSection] = useState([]);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [type, setType] = useState("industry");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [service_data, setServices] = useState([]);
  const [contactUs, setContactUs] = useState([]);
  const [subServiceList, setSubServiceList] = useState([]);
  const [verified, setVerified] = useState(false);
  const [fullArray, setFullArray] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.state);

  const [seoData, setSeoData] = useState(null);
  const url = window.location.href;

  useEffect(() => {
    const set = url.split("/industries/")[1];
    AOS.init();
    const fetchIndustries = async () => {
      try {
        const { data } = await axios.get(
          `https://backend.react.nvds.in/api/industries/${set}`
        );
        setIndustries({
          name: data.data.industry.name,
          content: data.data.industry.contents,
        });
        const seoData = data.data.industry;
        setSeoData(seoData);

        setFullArray( data.data.industry.contents)

        // let bannerSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "1"
        // );
        // setBannerSection(bannerSection);
        // let introductionSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "2"
        // );
        // setIntroductionSection(introductionSection);
        // if (
        //   introductionSection[0] &&
        //   introductionSection[0].extra_contents !== undefined
        // ) {
        //   const introductionData = introductionSection[0].extra_contents;
        //   setIntroductionData(introductionData);
        // }
        // let retailSoftwareSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "3"
        // );
        // setRetailSoftwareSection(retailSoftwareSection);
        // if (
        //   retailSoftwareSection[0] &&
        //   retailSoftwareSection[0].extra_contents !== undefined
        // ) {
        //   const retailSoftwareData = retailSoftwareSection[0].extra_contents;
        //   setRetailSoftwareData(retailSoftwareData);
        // }
        // let retailManagementSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "4"
        // );
        // setRetailManagementSection(retailManagementSection);
        // if (
        //   retailManagementSection[0] &&
        //   retailManagementSection[0].extra_contents !== undefined
        // ) {
        //   const retailManagementData =
        //     retailManagementSection[0].extra_contents;
        //   setRetailManagementData(retailManagementData);
        // }
        // let customerSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "5"
        // );
        // setCustomerSection(customerSection);
        // if (
        //   customerSection[0] &&
        //   customerSection[0].extra_contents !== undefined
        // ) {
        //   const customerData = customerSection[0].extra_contents;
        //   setCustomerData(customerData);
        // }
        // let finalSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "6"
        // );
        // setFinalSection(finalSection);
        // if (finalSection[0] && finalSection[0].extra_contents !== undefined) {
        //   const finalData = finalSection[0].extra_contents;
        //   setFinalData(finalData);
        // }
        // let letsTalkSection = data.data.industry.contents.filter(
        //   (sectionObject) => sectionObject.order === "8"
        // );
        // setLetsTalkSection(letsTalkSection);
      } catch (error) {
        console.log(error);
      }
    };
    fetchIndustries();
    contactDetails();
    fetchServices();
    setContentLoaded(true);
  }, [url]);

  const fetchServices = async () => {
    const { data } = await axios.get(
      "https://backend.react.nvds.in/api/services"
    );
    const service_data = data.data.services;
    setServices(service_data);
  };

  const baseUrl = "https://backend.react.nvds.in/storage/";

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm() && verified) {
      setIsLoading(true);
      
      const recaptchaToken = await window.grecaptcha.execute('6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0', { action: 'submit' });

      const data = { name, email, phone, type, service, message, recaptchaToken };
      axios
        .post("https://backend.react.nvds.in/api/form", data)
        .then((response) => {
          event.target.reset();
          const responseMsg = response.data.message;
          if (responseMsg === "Successful") {
            console.log(responseMsg);
            window.location.href = "/thankyou";
          } else {
            console.log(responseMsg);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const validateForm = () => {
    let isValid = true;
    if (!verified) {
      isValid = false;
    }
    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }
    if (!email.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!phone.trim()) {
      setPhoneError("Phone is required");
      isValid = false;
    } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone)) {
      setPhoneError("Phone is invalid");
      isValid = false;
    } else {
      setPhoneError("");
    }
    return isValid;
  };
  const contactDetails = async () => {
    try {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/general"
      );
      const contactUs = data.data.general;
      setContactUs(contactUs);
      // console.log("contact.....",contactUs);
      const allServices = data.data.all_services;
      const subservicesArray = allServices.flatMap(
        (service) => service.subservices
      );
      setSubServiceList(subservicesArray);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    swapDesign();
  }, [url , fullArray]);
  
  const swapDesign = () => {
    if (fullArray.length > 0) {
      const sortedArray = fullArray.sort((a, b) => parseInt(a.order) - parseInt(b.order));
      
      for (const section of sortedArray){ 
    
              if (section.section === "type1") {
              var y = section
              bannerSectionArray.push(y);
              setBannerSection(bannerSectionArray);
            } if (section.section === "type2") {
              var y = section
              introductionSectionArray.push(y)
              setIntroductionSection(introductionSectionArray);
              setIntroductionData(section.extra_contents)
            } if (section.section === "type3") {
              var y = section
              retailSoftwareSectionArray.push(y)
              setRetailSoftwareSection(retailSoftwareSectionArray)
              setRetailSoftwareData(section.extra_contents)
            } if (section.section === "type4") {
              var y = section
              retailManagementSectionArray.push(y)
              setRetailManagementSection(retailManagementSectionArray)
              setRetailManagementData(section.extra_contents)
            } if (section.section === "type5") {
              var y = section
              customerSectionArray.push(y)
              setCustomerSection(customerSectionArray)
              setCustomerData(section.extra_contents)
            } if (section.section === "type6") {
              var y = section
              finalSectionArray.push(y)
              setFinalSection(finalSectionArray)
              setFinalData(section.extra_contents)
            // } if (section.section === "type7") {
              // caseListSectionArray.push(y)
              // setCaseListSection(caseListSectionArray)
            } if (section.section === "type8") {
              letsTalkSectionArray.push(y)
              setLetsTalkSection(letsTalkSectionArray)
            }
          //   break;
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0";
    script.async = true;
    document.body.appendChild(script);
}, []); 

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0">
    <>
     {seoData && (
    <Helmet key={seoData.id}>
      {seoData.seo_description !== null && (
        <meta name="description" content={seoData.seo_description} />
      )}
      {seoData.seo_keywords !== null && (
        <meta name="keywords" content={seoData.seo_keywords} />
      )}
      {seoData.seo_title !== null && (
        <title>{seoData.seo_title}</title>
      )}
       {seoData.canonical_tag !== null && (
        <link
        rel="canonical"
        href={seoData.canonical_tag}
      />
      )}
      {seoData.schema !== null && (
        <script type="application/ld+json">
         {seoData.schema}
          </script>
      )}
    </Helmet>
  )}

{/* TYPE 1 */}
      {bannerSection.length > 0 ? (
        <>
          {bannerSection.map((bannerSection, index) => (
            
            <section
              className="pagebanner bgc-primary type1"
              key={index}
              style={{
                // backgroundImage: `url(${baseUrl + data.image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom right",
              }}
            >
              {bannerSection.image == null ? (
                        ""
                      ) : (
            <img
              className="banner-img"
              src={baseUrl + bannerSection.image}
              alt={bannerSection.title}
              loading="lazy"
            /> )}
            {bannerSection.image == null ? (
                      ""
                    ) : (
          <img
            className="mobile-banner-img"
            src={baseUrl + bannerSection.mobile_image}
            alt={bannerSection.title}
            loading="lazy"
          /> )}
              <div className="container">
                <div
                  className="pagebanner-left"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h1 className="text-light text-capitalize">{bannerSection.title}</h1>
                  <p
                    className="text-light"
                    dangerouslySetInnerHTML={{ __html: bannerSection.description }}
                  ></p>
                    {bannerSection.button_title == null ? (
                        ""
                      ) : (
                      <Link
                      to={bannerSection.button_link}
                      className="button text-light"
                      onClick={
                      bannerSection.button_link === "#" ? scrollToEnquiry : null
                      }>
                      {bannerSection.button_title} <BsArrowRight /></Link>)}
                </div>
              </div>
              {bannerSection.image == null ? (
                        ""
                      ) : (
              <img
                className="mobile-banner"
                src={baseUrl + bannerSection.image}
                alt={bannerSection.title}
                loading="lazy"
              /> )}
            </section>
          ))}
        </>
      ) : (
        " "
      )}

{/* TYPE 2 */}
      {introductionSection.length > 0 ? (
        <>
          {introductionSection.map((introductionSection, index) => (
            <section className="content introduction bg-light type2" key={index}>
              <div className="container">
                <>
                  <div className="content-full text-center pl-60">
                    <h2
                      className="text-dark"
                      dangerouslySetInnerHTML={{ __html: introductionSection.title }}
                    ></h2>
                    <p className="text-dark text-center">{introductionSection.description}</p>
                    {introductionSection.button_title == null ? (
                        ""
                      ) : (
                      <Link
                      to={introductionSection.button_link}
                      className="button text-dark mb-4 d-block"
                      onClick={
                        introductionSection.button_link === "#" ? scrollToEnquiry : null
                      }>
                      {introductionSection.button_title} <BsArrowRight /></Link>)}
                  </div>
                </>
              </div>
                    {introductionData.length > 0 ? (
              <div className="container industry-features pt-0 pb-0">
            <div
              className="content-full"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Slider {...settings_IntroductionSection}>
                {introductionData.map((introductionData) => (
                  <>
                    <div className="item">
                      {introductionData.image == null ? (
                        ""
                      ) : (
                        <img src={baseUrl + introductionData.image} alt={introductionData.title} loading="lazy" />
                      )}
                      <h4 className="text-dark" style={{ textAlign: "center" }}>
                        {introductionData.title}
                      </h4>
                      <p style={{ textAlign: "center" }}>{introductionData.description}</p>
                      {introductionData.button_title == null ? (
                    ""
                  ) : (
                  <Link
                  to={introductionData.button_link}
                  className="button text-light"
                  onClick={
                    introductionData.button_link === "#" ? scrollToEnquiry : null
                  }>
                  {introductionData.button_title} <BsArrowRight /></Link>)}
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
      ) : (
        ""
      )}
            </section>
          ))}
        </>
      ) : (
        ""
      )}

      {retailManagementSection.length > 0 ? (
        <section className="content bgc-primary type4">
          <div className="container-fluid column-reverse why-us why-us-industry-details ind case-studies">
            <div
              className="content-left"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {retailManagementSection.map((retailManagementSection, index) => (
                <div key={index}>
                  <h2 className="text-white">{retailManagementSection.title}</h2>
                  <h3 className="text-white">{retailManagementSection.subtitle}</h3>
                  <p className="text-white">{retailManagementSection.description}</p>
                  {retailManagementSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={retailManagementSection.button_link}
                        className="button text-light"
                        onClick={
                          retailManagementSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {retailManagementSection.button_title} <BsArrowRight /></Link>)}
                </div>
              ))}
            </div>
            <div className="content-right">
              <Slider {...settings_RetailManagementSection}>
                {retailManagementData.map((retailManagementData, i) => (
                  <div className="item" key={i}>
                    {retailManagementData.image == null ? (
                      ""
                    ) : (
                      <img
                        src={baseUrl + retailManagementData.image}
                        alt={retailManagementData.title}
                        className="img-invert"
                        loading="lazy"
                      />
                    )}
                    <h4 className="text-light">{retailManagementData.title}</h4>
                    <p className="text-light">{retailManagementData.description}</p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}


      {retailSoftwareSection.length > 0 ? (
        <section className="content pt-4 type3">
          <div className="container industry-features pb-0">
            <div
              className="content-full"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {retailSoftwareSection.map((retailSoftwareSection) => (
                <>
                <h2 className="text-dark w-100 text-center">{retailSoftwareSection.title}</h2>
                <p className="text-dark w-100 text-center">{retailSoftwareSection.description}</p>
                {retailSoftwareSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={retailSoftwareSection.button_link}
                        className="button text-dark w-100 text-center d-block mb-4"
                        onClick={
                          retailSoftwareSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {retailSoftwareSection.button_title} <BsArrowRight /></Link>)}
                </>
              ))}
              {retailSoftwareData.length>0 ? (
              <Slider {...settings_RetailSoftwareSection}>
                {retailSoftwareData.map((retailSoftwareData, index) => (
                  <>
                    <div className="item" key={index}>
                      <h4 className="text-dark" style={{ textAlign: "center" }}>
                        <b>{retailSoftwareData.title}</b>
                      </h4>
                      <p style={{ textAlign: "center" }}>{retailSoftwareData.description}</p>
                    </div>
                  </>
                ))}
              </Slider>
      ) : (
        ""
      )}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {customerSection.length > 0 ? (
        <section className="content type5">
          <div className="container-fluid features-with-borders industry-features pb-0">
            <div className="content-left">
              <Slider {...settings_CustomerSection}>
                {customerData.map((customerData, i) => (
                  <>
                    <div className="services-wrapper" key={i}>
                      {customerData.image == null ? (
                        ""
                      ) : (
                        <img
                          className="no-filter_"
                          src={baseUrl + customerData.image}
                          alt="listImg"
                          loading="lazy"
                        />
                      )}
                      <h4 className="text-dark text-center">
                        <b>{customerData.title}</b>
                      </h4>
                      <p className="text-dark text-center">
                        {customerData.description}
                      </p>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
            <div
              className="content-right"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {customerSection.map((customerSection) => (
                <>
                  <h2 className="text-primary">{customerSection.title}</h2>
                  <h3 className="text-primary">{customerSection.subtitle}</h3>
                  <p className="text-dark">{customerSection.description}</p>
                  {customerSection.button_title == null ? (
                        ""
                      ) : (
                        <Link
                        to={customerSection.button_link}
                        className="button text-dark "
                        onClick={
                          customerSection.button_link === "#" ? scrollToEnquiry : null
                        }>
                        {customerSection.button_title} <BsArrowRight /></Link>)}
                </>
              ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

{/* FULL WIDTH SECTION WITH SLIDER */}
      {finalSection.length > 0 ? (
        <section className="content bgc-primary type6">
          <div className="container case-studies slider-center-arrow mb-0">
            <div
              className="content-full"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {finalSection.map((finalSection, i) => (
                <div key={i}>
                  <h2 className="text-light w-100 text-center">{finalSection.title}</h2>
                  <h3 className="text-light w-100 text-center">
                    {" "}
                    {finalSection.subtitle}
                  </h3>
                  <p className="text-white text-center mt-3">
                  {finalSection.description}
                </p>
                {finalSection.button_title == null ? (
                    ""
                  ) : (
                  <Link
                  to={finalSection.button_link}
                  className="button text-blue text-center d-block fw-bold"
                  onClick={
                    finalSection.button_link === "#" ? scrollToEnquiry : null
                  }>
                  {finalSection.button_title} <BsArrowRight /></Link>)}
                </div>
              ))}
              <Slider {...settings_FinalSection}>
                {finalData.map((finalData, i) => (
                  <>
                    <div className="item" key={i}>
                      {/* {item.image == null ? "" :
                <img className='img-invert' src={baseUrl + item.image} alt="listImg" /> } */}
                      <h4 className="text-light text-center">{finalData.title}</h4>
                      <p className="text-light text-center">
                        {finalData.description}
                      </p>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {letsTalkSection.length > 0 ? (
        <section className="content bgc-primary">
          <div className="container">
            {letsTalkSection.map((letsTalkSection, i) => (
              <div
                className="content-full"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="text-white text-center">{letsTalkSection.title}</h2>
                <p className="text-white text-center mt-3">
                  {letsTalkSection.description}
                </p>
                {letsTalkSection.button_title == null ? (
                        ""
                      ) : (
                <Link to={letsTalkSection.button_link} className="text-center d-block">
                  {letsTalkSection.button_title}
                </Link>)}
              </div>
            ))}
          </div>
        </section>
      ) : (
        ""
      )}

{bannerSection.length > 0 ? (
      <section className="content">
        <div className="form-anchor" id="enquiry"></div>
        <div className="container service-contact-box">
          <div
            className="content-left"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-dark">Discuss Your Project</h2>
            <h3 className="text-dark">With Us Now</h3>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col form__linput">
                  <div className={`form-floating ${name && "active"}`}>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      name="name"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="name">Your Name *</label>
                  </div>
                  {nameError && <p className="error">{nameError}</p>}
                </div>
                <div className="col">
                  <div className={`form-floating ${email && "active"}`}>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      name="email"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="email">Email Address *</label>
                  </div>
                  {emailError && <p className="error">{emailError}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={`form-floating ${phone && "active"}`}>
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      name="phone"
                      placeholder=" "
                      autoComplete="off"
                    />
                    <label htmlFor="phone">Phone Number *</label>
                  </div>
                  {phoneError && <p className="error">{phoneError}</p>}
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      id="service"
                      placeholder=" "
                      name="service"
                      value={service}
                      readOnly
                      onChange={(event) => setService(event.target.value)}
                    />
                    <label htmlFor="service">Select Service *</label>
                    <ul className="service-list">
                      <li>
                        <span onClick={() => setService("")}>
                          Select Service
                        </span>
                      </li>
                      {service_data &&
                        service_data.map((item) => (
                          <React.Fragment key={item.id}>
                            <li>
                              <span onClick={() => setService(item.slug)}>
                                {item.title}
                              </span>
                              {item.subservices.length > 0 && (
                                <ul>
                                  {item.subservices.map((data) => (
                                    <React.Fragment key={data.id}>
                                      <li>
                                        <span
                                          onClick={() =>
                                            setService(
                                              `${item.slug}-${data.slug}`
                                            )
                                          }
                                        >
                                          {data.name}
                                        </span>
                                        {data.innerservices.length > 0 && (
                                          <ul>
                                            {data.innerservices.map(
                                              (object) => (
                                                <li key={object.id}>
                                                  <span
                                                    onClick={() =>
                                                      setService(
                                                        `${item.slug}-${data.slug}-${object.slug}`
                                                      )
                                                    }
                                                  >
                                                    {object.name}
                                                  </span>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    </React.Fragment>
                                  ))}
                                </ul>
                              )}
                            </li>
                          </React.Fragment>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={`form-floating ${message && "active"}`}>
                    <textarea
                      className="form-control"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      name="message"
                      rows="3"
                      placeholder=" "
                      autoComplete="off"
                    ></textarea>
                    <label htmlFor="message">Your Message (optional)</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                <GoogleReCaptcha
                  sitekey="6LcA6jApAAAAANkncVi3XZ3gzXh4FZkkp1Lln5x0"
                  onVerify={() => {
                    console.log("reCAPTCHA verified!");
                    setVerified(true);
                  }}
                />
                </div>
              </div>
              <div className="row">
                <div className="form-group text-left">
                  <button type="submit">
                    {isLoading ? (
                      <>
                      <div className="btn-loader"></div> Sending ...
                      </>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-black">Our Contact Details</h2>
            <div className="contact-box">
              <small className="d-block">Call Us</small>
              <a href={`tel:${String(contactUs.mobile).replace(/\s/g, "")}`}>
                <b>{contactUs.mobile}</b>
              </a>
            </div>
            <div className="contact-box">
              <small className="d-block">Address</small>
              <b dangerouslySetInnerHTML={{ __html: contactUs.address }}></b>
            </div>
            <div className="contact-box">
              <small className="d-block">Email Address</small>
              <a href={`mailto:${String(contactUs.email)}`}>
                <b>{contactUs.email}</b>
              </a>
            </div>
          </div>
        </div>
      </section>
      ) : (
        ""
      )}
     {contentLoaded && <Footer initiallyHidden={false} />}
    </>
    </GoogleReCaptchaProvider>
  );
};
export default IndustryDetails;
