import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
// import ContactBg from "../images/case-study.webp";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';

const CaseStudies = () => {
  const [caseList, setCaseList] = useState([]);
  const [caseDetails, setCaseDetails] = useState([]);
  const [animationComplete, setAnimationComplete] = useState(false);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [seoData, setSeoData] = useState(null);
  const url = window.location.href;
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    AOS.init();
    fetchCaseStudy();
    setContentLoaded(true);
  }, [url]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const fetchCaseStudy = async () => {
    const { data } = await axios.get(
      `https://backend.react.nvds.in/api/case-studies`
    );
    const seoData = data.data.case_study_settings;
    const caseList = data.data.case_studies;
    const caseDetails = data.data.case_study_settings;
    setCaseList(caseList);
    setCaseDetails(caseDetails);
    setSeoData(seoData);
  };

  const navigate = useNavigate();
  const handleCasestudyClick = (caseService) => {
    navigate(
      `/casestudiesdetails/${caseService.service_id_slug}/${caseService.case_study_slug}`,
      {}
    );
  };

  return (
    <>
      {seoData && (
        <Helmet key={seoData.id}>
          {seoData.seo_description !== null && (
            <meta name="description" content={seoData.seo_description} />
          )}
          {seoData.seo_keywords !== null && (
            <meta name="keywords" content={seoData.seo_keywords} />
          )}
          {seoData.seo_title !== null && <title>{seoData.seo_title}</title>}
          {seoData.canonical_tag !== null && (
            <link rel="canonical" href={seoData.canonical_tag} />
          )}
          {seoData.schema !== null && (
     <script type="application/ld+json">
      {seoData.schema}
       </script>
)}
        </Helmet>
      )}

      <div
        className={`pagebanner bgc-primary ${
          animationComplete ? "animate" : ""
        }`}
      >
        {caseDetails.image == null ? (
                        ""
                      ) : (
        <img
          className="banner-img"
          src={baseUrl + caseDetails.image}
          alt="title"
          loading="lazy"
        /> )}
        {caseDetails.mobile_image == null ? (
                      ""
                    ) : (
          <img
            className="mobile-banner-img"
            src={baseUrl + caseDetails.mobile_image}
            alt={caseDetails.title}
            loading="lazy"
          /> )}
        <div className="container">
          <div className="pagebanner-left">
            <h1 className="text-light">{caseDetails.title}</h1>
            <div class="scrollbar-2 h-270 pl-60 ml--60">
            <p className="text-white" dir="ltr" dangerouslySetInnerHTML={{ __html: caseDetails.description }}></p></div>
          </div>
        </div>
      </div>

      <section
        className={`content blog ${animationComplete ? "animate" : ""}`}
      >
        <div className="container justify-content-center blog-wrapr">
          {caseList.map((caseItem, index) => (
            <div className="item" key={index}>
              <div
                onClick={() => handleCasestudyClick(caseItem)}
                className="link"
              >
                <h4 className="text-center text-dark">{caseItem.title}</h4>
                <p className="text-center text-dark" dangerouslySetInnerHTML={{ __html: caseItem.content }}></p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  );
};

export default CaseStudies;
