import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../components/Footer';

const TermsAndConditions = () => {

    const [termsAndConditions, TermsAndConditionsData] = useState([]);
    const [contentLoaded, setContentLoaded] = useState(false);
    const baseUrl = "https://backend.react.nvds.in/storage/";

    useEffect(() => {
        AOS.init();
        const whyUsApi = async () => {
            const { data } = await axios.get(
                "https://backend.react.nvds.in/api/terms"
            );
            const termsAndConditions = data.data.terms
            TermsAndConditionsData(termsAndConditions);
        };
        whyUsApi();
        setContentLoaded(true);
    }, []);

  return (
    <>
    <section className="pagebanner bg-light py-5 policy-banner"
        style={{
            minHeight: "30vh",
        }}>
             {termsAndConditions.image == null ? (
                          ""
                        ) : (
                <img className="mobile-banner-img" 
                src={baseUrl + termsAndConditions.image} 
                alt="title" 
                loading="lazy" /> )}

        <div className="container">
            <div className="pagebanner-left w-100">
                <h1 className="text-dark">{termsAndConditions.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: termsAndConditions.content }}></div>
            </div>
        </div>
    </section>
    {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  )
}

export default TermsAndConditions
