import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BsArrowRight } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import "./home.css";
import { Helmet } from "react-helmet";
import Footer from '../../components/Footer';
import Testimonials from '../../components/Testimonials';

const LazyWhyUs = React.lazy(() => import("../../components/WhyUs"));
const LazyOurTeam = React.lazy(() => import("../../components/OurTeam"));
const LazyServices = React.lazy(() => import("../../components/ServicesHome"));
const LazyIndustries = React.lazy(() => import("../../components/Industries"));
const LazyCaseStudies = React.lazy(() => import("../../components/CaseStudiesPage"));


const Home = () => {
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [schemaData, setSchemaData] = useState("");
  // const [animationComplete, setAnimationComplete] = useState(false);
  const [whyUsData, setWhyUsData] = useState(null);
  const [drivenData, setDrivenData] = useState(null);
  const [caseStudiesData, setCaseStudiesData] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const [seo, setSeo] = useState({ 
    seo_description: "",
    seo_keywords: "",
    seo_title: "",
  });
  const [apiData, setApiData] = useState({
    headerCaption: {},
    introduction: {},
    ourStory: {},
    values: {},
    actions: {},
    industries: [],
    servicesOffer: {},
  });

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setAnimationComplete(true);
  //   }, 10);

  //   return () => clearTimeout(timeout);
  // }, []);

  useEffect(() => {
    // AOS.init();
    const fetchData = async () => {
      try {
        const [
          generalData,
          homeData,
          industriesData,
          servicesData,
          whyUsResponse,
          drivenResponse,
          caseStudiesResponse,
        ] = await Promise.all([
          axios.get("https://backend.react.nvds.in/api/general"),
          axios.get("https://backend.react.nvds.in/api/home"),
          axios.get("https://backend.react.nvds.in/api/section_7_industries"),
          axios.get("https://backend.react.nvds.in/api/section_5_Services"),
          axios.get("https://backend.react.nvds.in/api/section_8_why"),
          axios.get("https://backend.react.nvds.in/api/section_3_driven"),
          axios.get("https://backend.react.nvds.in/api/section_6_case"),
        ]);

        const seo = generalData.data.data.general;
        setSeo(seo);

        const headerCaption = generalData.data.data.home_sliders[0];
        const introduction = homeData.data.data.all_sections[0];
        const ourStory = homeData.data.data.all_sections[1];
        const values = homeData.data.data.all_sections[2];
        const actions = homeData.data.data.all_sections[3];
        const industries = industriesData.data.data.section_7_industries;
        const servicesOffer = servicesData.data.data.section_5_Services;

        setApiData({
          headerCaption,
          introduction,
          ourStory,
          values,
          actions,
          industries,
          servicesOffer,
        });

        setWhyUsData(whyUsResponse.data.data.section_8_why);
        setDrivenData(drivenResponse.data.data.section_3_driven);
        setCaseStudiesData(caseStudiesResponse.data.data.section_6_case);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    setContentLoaded(true);
  }, []);

  useEffect(() => {
    AOS.init();
    const schema = async () => {
      const { data } = await axios.get('https://backend.react.nvds.in/api/schema');
      const schemaData = data.data.schema;
      setSchemaData(schemaData);
    }
    schema();
  }, []);
  
  return (
    <React.Fragment>
{seo && (
  <Helmet>
    {seo.seo_description !== null && (
      <meta name="description" content={seo.seo_description} />
    )}  
    {seo.seo_keywords !== null && (
      <meta name="keywords" content={seo.seo_keywords}/>
    )} 
    {seo.seo_title !== null && (
      <title>{seo.seo_title}</title>
    )} 
    {seo.seo_title !== null && (
      <title>{seo.seo_title}</title>
    )}
     {seo.canonical_tag !== null && (
    <link
                rel="canonical"
                href={seo.canonical_tag}
              />
      )}
       {schemaData.description !== null && (
     <script type="application/ld+json">
      {schemaData.description}
       </script>
)}
{apiData.headerCaption.schema !== null && (
     <script type="application/ld+json">
      {apiData.headerCaption.schema}
       </script>
)}

  </Helmet>
)}  
{apiData.headerCaption && apiData.headerCaption.title && (
     <section
        className={`banner bgc-primary`}
        // className="banner"
        // loading="lazy"
        
      >
        {/* style={{
          backgroundImage: `url(${baseUrl + apiData.headerCaption.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
        }} */}
        {/* <div className='mobile-banner' style={{backgroundImage: `url(${baseUrl + headerCaption.mobile_slider})`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'right center',}}></div> */}
        <img className="bannerimg" src={baseUrl + apiData.headerCaption.image} alt="banner" />
        <img className="mobilebannerimg" src={baseUrl + apiData.headerCaption.mobile_slider} alt="banner" />
        <div className="container">
          <div className="banner-left">
            <h1 className="text-light">{apiData.headerCaption.title}</h1>
            <h2
              className="text-light"
              dangerouslySetInnerHTML={{ __html: apiData.headerCaption.sub_title }}
            ></h2>
             <p
              className="text-light" 
              dangerouslySetInnerHTML={{ __html: apiData.headerCaption.description }}
            ></p>
            <Link to={apiData.headerCaption.link} className="button">
              {apiData.headerCaption.button_title}
              <BsArrowRight />
            </Link>
          </div>
        </div>
      </section>
      )}
      {apiData.introduction && apiData.introduction.title && (
      <section
        className={`content introduction bgc-primary`}
        style={{
          backgroundImage: `url(${baseUrl + apiData.introduction.image1})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
        }}
      >
        <div className="container">
          <div
            className="content-left"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {apiData.introduction.subtitle && (
            <h3
              className="text-light"
              dangerouslySetInnerHTML={{ __html: apiData.introduction.subtitle }}
            ></h3>)}
            {apiData.introduction.subtitle && (
            <h2 className="text-blue">{apiData.introduction.title}</h2>)}
            {apiData.introduction.content && (
            <p className="text-light" dangerouslySetInnerHTML={{ __html: apiData.introduction.content }}></p>)}
          </div>
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {apiData.introduction.image2 && (
            <div
              className="spacer-01 d-none-img"
              style={{
                backgroundImage: `url(${baseUrl + apiData.introduction.image2})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                width: "400px",
                height: "400px",
              }}
            ></div>)}
          </div>
        </div>
      </section>
      )}
      {apiData.ourStory && apiData.ourStory.title && (
      <section  className={`content our-story`}>
        <div className="container">
          <div
            className="content-left pl-60"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-primary">{apiData.ourStory.title}</h3>
            {apiData.ourStory.content && (
            <div className="scrollbar-2 h-270 pl-60 ml--60">
              <div dir="ltr" dangerouslySetInnerHTML={{ __html: apiData.ourStory.content }}></div>
            </div>)}
            
            <div className="spacer200"></div>
            {apiData.ourStory.subtitle && (
            <h2 className="text-primary">
              <b>{apiData.ourStory.subtitle}</b>
            </h2>)}
            {apiData.ourStory.content2 && (
            <div className="scrollbar-2 h-270 pl-60 ml--60">
              <div dir="ltr" dangerouslySetInnerHTML={{ __html: apiData.ourStory.content2 }}></div>
            </div>)}
            {apiData.ourStory.link && apiData.ourStory.button_title && (
            <Link to={apiData.ourStory.link} className="button">{" "}
              {apiData.ourStory.button_title}
              <BsArrowRight />
            </Link>)}
          </div>
          {apiData.ourStory.image1 && (
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="spacer-01"
              style={{
                backgroundImage: `url(${baseUrl + apiData.ourStory.image1})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>)}
        </div>
      </section>
      )}
       {drivenData !== null && (
      <React.Suspense>
        <LazyOurTeam />
      </React.Suspense>)}
      {apiData.values && apiData.values.title && apiData.values.image1 && 
      apiData.values.subtitle && apiData.values.content && apiData.values.link && 
      apiData.values.button_title && (
      <section className={`content our-values`} >
        <div className="container">
          <div
            className="content-left"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {apiData.values.image1 == null ? (
                      ""
                    ) : (
            <div
              className="spacer-01"
              style={{
                backgroundImage: `url(${baseUrl + apiData.values.image1})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div> )}
          </div>
          <div
            className="content-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-primary">{apiData.values.title}</h3>
            <h2 className="text-primary">{apiData.values.subtitle}</h2>
            <p className="text-dark" dangerouslySetInnerHTML={{ __html: apiData.values.content }}></p>
            <Link to={apiData.values.link}>
              {" "}
              {apiData.values.button_title} <BsArrowRight />
            </Link>
          </div>
        </div>
      </section>)}
      {apiData.servicesOffer && apiData.servicesOffer.title && (
      <section className={`services content bgc-primary`}>
        <div className="container">
          <div
            className="content-left"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 className="text-light">{apiData.servicesOffer.title}</h2>
            <h3 className="text-light">{apiData.servicesOffer.subtitle}</h3>
            <p className="text-light" dangerouslySetInnerHTML={{ __html: apiData.servicesOffer.content }}></p>
          </div>
          <div className="content-right">
            <React.Suspense>
              <LazyServices />
            </React.Suspense>
          </div>
        </div>
      </section>)}
      {apiData.industries && apiData.industries.title && (
      <section
        className={`industries content bgc-primary`}
        style={{
          backgroundImage: `url(${baseUrl + apiData.industries.image1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center right",
        }}
      >
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <div className="content-left">
            <h3 className="text-blue">{apiData.industries.title}</h3>
            <h2 className="text-blue">{apiData.industries.subtitle}</h2>
            <p className="text-light" dangerouslySetInnerHTML={{ __html: apiData.industries.content }}></p>
          </div>
          <div className="content-right"></div>
        </div>
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <div className="content-full">
            <React.Suspense>
              <LazyIndustries />
            </React.Suspense>
          </div>
        </div>
      </section>)}
      {caseStudiesData !== null && ( 
      <section className={`bgc-primary content`}><React.Suspense><LazyCaseStudies /></React.Suspense></section>)}
      {whyUsData !== null && (
      <section className={`content`}><React.Suspense><LazyWhyUs /></React.Suspense></section>)}
      {apiData.actions && apiData.actions.title && (
      <section className={`call-to-action`}>
        <div className="container">
          <div className="row" data-aos="fade-up" data-aos-duration="1000">
            <h3 className="text-blue">{apiData.actions.title}</h3>
            <h4 className="text-light">{apiData.actions.subtitle}</h4>
            <Link to={apiData.actions.link}>
              {" "}
              {apiData.actions.button_title} <BsArrowRight />
            </Link>
          </div>
        </div>
      </section>)}
      {contentLoaded && (
        <section>
      <Testimonials initiallyHidden={false} />
      </section>)}
      {contentLoaded && <Footer initiallyHidden={false} />}
    </React.Fragment>

  );
};
export default Home;
