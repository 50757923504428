import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import "./style.css";
import "./blog.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import ContactBg from "../images/latest-blogs.webp";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';

const Blogs = () => {
  const [blogLists, setBlogLists] = useState([]);
  const [blogging, setBlogging] = useState([]);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const navigate = useNavigate();
  const [animationComplete, setAnimationComplete] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    AOS.init();
    const blogMain = async () => {
      const { data } = await axios.get(
        "https://backend.react.nvds.in/api/blogs"
      );
      const blogLists = data.data.blogLists;
      setBlogLists(blogLists);
      const blogging = data.data.blog;
      setBlogging(blogging);
    };
    blogMain();
    setContentLoaded(true);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationComplete(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleBlogClick = (blog) => {
    navigate(`/blogs/${blog.slug}`, {});
  };
  return (
    <>

    <Helmet> 
      {blogging.seo_description !== null && (
        <meta name="description" content={blogging.seo_description} />
      )}  
      {blogging.seo_keywords !== null && (
         <meta name="keywords" content={blogging.seo_keywords}/>
      )} 
      {blogging.seo_title !== null && (
          <title>{blogging.seo_title}</title>
      )} 
      {blogging.canonical_tag !== null && (
        <link
        rel="canonical"
        href={blogging.canonical_tag} 
      />
      )}
      {blogging.schema !== null && (
        <script type="application/ld+json">
         {blogging.schema}
          </script>
      )}
    </Helmet>
    {blogging && (
      <section className={`pagebanner bgc-primary ${animationComplete ? "animate" : ""}`}
      style={{
        // backgroundImage: `url(${ContactBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right center",
        // minHeight: "80vh",
      }}
      >
        {blogging.image == null ? (
                        ""
                      ) : (
        <img
              className="banner-img"
              src={baseUrl + blogging.image}
              alt="title"
              loading="lazy"
            /> )}
             {blogging.mobile_image == null ? (
                      ""
                    ) : (
          <img
            className="mobile-banner-img"
            src={baseUrl + blogging.mobile_image}
            alt={blogging.title}
            loading="lazy"
          /> )}
        <div className="container">
          <div className="pagebanner-left">
            <h1 className="text-light">{blogging.title}</h1>
            <div class="scrollbar-2 h-270 pl-60 ml--60">
            <p className="text-white" dir="ltr">
              {blogging.description}
            </p></div>
            {/* <Link to="/contact" className="button text-dark"> Enquire Now <BsArrowRight /> </Link> */}
          </div>
        </div>
      </section>)}
      {blogLists && (
      <section className={`content blog ${animationComplete ? "animate" : ""}`}>
        <div className="container justify-content-center blog-wrapr">
          {blogLists.map((list, index) => (
            <div className="item">
              <div
                key={index}
                onClick={() => handleBlogClick(list)}
                className="link-whole"
                rel="noopener noreferrer"
              >
                <div
                  className="post-img"
                  style={{
                    backgroundImage: `url(${baseUrl + list.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>                
                <h4>{list.title}</h4>
                <p style={{ color: "#8e8e8e" }}>{list.slug}</p>
                <span className="readmore">
                  Read more&nbsp;
                  <BsArrowRight />
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>)}
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  );
};
export default Blogs;
