import React, { useState, useEffect } from "react";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";
import axios from "axios";
import { AiOutlineDown } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet";

const Navbars = () => {

  const [serviceUuid, setServiceUuid] = useState([]);
  const [industries_data, setIndustries] = useState([]);
  const [service_data, setServices] = useState([]);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const [logo, setLogo] = useState([]);
  const [showNavbar, setShowNavbar] = useState(false);
  // const [GTM_ID , setGTM_ID] = useState([]);
  const [showActive, setShowActive] = useState({
    home: true,
    about: false,
    services: false,
    industries: false,
    blog: false,
    casestudies: false,
    contact: false,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  // const [animationComplete, setAnimationComplete] = useState(false);
 

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setAnimationComplete(true);
  //   }, 10);
  //   return () => clearTimeout(timeout);
  // }, []);
  
  const path = window.location.href.split("/");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const logoResponse = await axios.get(
          "https://backend.react.nvds.in/api/general"
        );
        const logo = logoResponse.data.data.general;
        setLogo(logo);

        const industriesResponse = await axios.get(
          "https://backend.react.nvds.in/api/industries"
        );
        const industries_data = industriesResponse.data.data.industries;
        setIndustries(industries_data);

        const servicesResponse = await axios.get(
          "https://backend.react.nvds.in/api/services"
        );
        const service_data = servicesResponse.data.data.services;
        setServices(service_data);
        // gtmSeo();
          
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    if (path[3] === "") {
      setShowActive({
        home: true,
        about: false,
        services: false,
        industries: false,
        blog: false,
        casestudies: false,
        contact: false,
      });
    }
    if (path[3] === "about") {
      setShowActive({
        home: false,
        about: true,
        services: false,
        industries: false,
        blog: false,
        casestudies: false,
        contact: false,
      });
    }
    if (path[3] === "services") {
      setShowActive({
        home: false,
        about: false,
        services: true,
        industries: false,
        blog: false,
        casestudies: false,
        contact: false,
      });
      setShowNavbar(true);
      setShowSubMenu(true);
    }
    if (path[3] === "industries") {
      setShowActive({
        home: false,
        about: false,
        services: false,
        industries: true,
        blog: false,
        casestudies: false,
        contact: false,
      });
    }
    if (path[3] === "blogs") {
      setShowActive({
        home: false,
        about: false,
        services: false,
        industries: false,
        blog: true,
        casestudies: false,
        contact: false,
      });
    }
    if (path[3] === "casestudies") {
      setShowActive({
        home: false,
        about: false,
        services: false,
        industries: false,
        blog: false,
        casestudies: true,
        contact: true,
      });
    }
    if (path[3] === "contact") {
      setShowActive({
        home: false,
        about: false,
        services: false,
        industries: false,
        blog: false,
        casestudies: false,
        contact: true,
      });
    }
  }, [serviceUuid]);

  const navigate = useNavigate();

  const [isMenuOpenFirst, setMenuOpenFirst] = useState(false);
  const [isMenuOpenSecond, setMenuOpenSecond] = useState(false);

  const resetMenu = () => {
    window.location.reload();
    setMenuOpenFirst(false);
    setMenuOpenSecond(false);

  };

  const handleServiceClick = (service) => {
    if (service.custom_url === "/") {
      window.location.href = "/";
    } else {
      navigate(`/Services/${service.slug}`, {
        state: {
          serviceLink: `/Services/${service.slug}`,
          id: service.slug,
        },
      });
      setIsMenuOpen(false);
      setShowNavbar(false);
      setShowSubMenu(false);
    }
    setMenuOpenFirst(false);
    setMenuOpenSecond(false);
    window.location.reload();
  };

  const handleIndustryClick = (industry) => {
    if (industry.custom_url === "/") {
      window.location.href = "/";
    } else {
      navigate(`/industries/${industry.slug}`, {
        state: { id: industry.uuid, name: industry.name },
      });
      setIsMenuOpen(false);
      setShowNavbar(false);
      setShowSubMenu(false);
    }
    setMenuOpenFirst(false);
    setMenuOpenSecond(false);
  };

  // const gtmSeo = async () => {
  //   const { data } = await axios.get(
  //     `https://backend.react.nvds.in/api/gtm`
  //   );
  //   const GTM_ID = data.data.gtm;
  //   setGTM_ID(GTM_ID)
  // };


  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={baseUrl + logo.favicon}></link>
        {/* <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/${GTM_ID.head}';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID.head}'); 
          `}
        </script> */}
      </Helmet>
      <nav className={`navbar navbar-expand-lg navbar-dark ${scrolling ? 'menufixed' : ''}`}>
        <div
          className={`container`}
          onMouseEnter={() => {
            setShowNavbar(true);
            setShowSubMenu(true);
          }}
          onMouseLeave={() => {
            setShowNavbar(true);
            setShowSubMenu(true);
          }}
        >
          <a className="navbar-brand fw-bold" href="/">
          {logo.logo == null ? (
                        ""
                      ) : (
            <img
              src={baseUrl + logo.logo}
              alt="Intellect.Works"
              width="150"
              height="66"
              // loading="lazy"
            /> )}
          </a>
          <button
            className="btn justify-content-end toggleMenu"
            aria-label="Learn more about Intellect.Works on Facebook"
            onClick={toggleMenu}
          >
            Menu
            {!isMenuOpen ? (
              <RxHamburgerMenu className="navbar-toggler-icon " />
            ) : (
              <AiOutlineClose fontSize={14} className="btn-close" />
            )}
          </button>
          <div
            className={`collapse navbar-collapse ${
              isMenuOpen ? "show" : "hide"
            } justify-content-end`}
            id="a3-main-menu"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className={showActive.home ? "nav-link active" : "nav-link"}
                  to="/"
                  onClick={() => {
                    setShowActive({
                      home: true,
                      about: false,
                      services: false,
                      industries: false,
                      blog: false,
                      casestudies: false,
                      contact: false,
                    });
                    closeMenu();
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={showActive.about ? "nav-link active" : "nav-link"}
                  to="/about"
                  onClick={() => {
                    setShowActive({
                      home: false,
                      about: true,
                      services: false,
                      industries: false,
                      blog: false,
                      casestudies: false,
                      contact: false,
                    });
                    closeMenu();
                  }}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item dropdown hasdropdown megamenu">
                <a
                  className={
                    showActive.services
                      ? "nav-link active dropdown-toggle firstparent"
                      : "nav-link dropdown-toggle firstparent"
                  }
                  // href="javascript:void(0);"
                >
                  Services
                </a>
                {service_data.length > 0 && (
                  <AiOutlineDown
                    className="dropdownopener"
                    onClick={() => {
                      setShowSubMenu(true);
                    }}
                  />
                )}
                <ul className="dropdown-menu dropdown-menu-level-1">
                  {service_data &&
                    service_data.map((item) => (
                      <li
                        className="nav-item dropend hasdropdown level-1-list"
                        key={item.uuid}
                        id={`tab-${item.uuid}`}
                      >
                        <a
                          className="nav-link dropdown-toggle"
                          // href="javascript:void(0);"
                          style={{
                            display: showSubMenu ? "inline-block" : "none",
                            /////////// -- code commented --/////////
                            // ? "inline-block" : "none",          //
                            /////////// -- code commented --/////////
                          }}
                          onClick={() =>
                            setShowActive({
                              home: false,
                              about: false,
                              services: true,
                              industries: false,
                              blog: false,
                              casestudies: false,
                              contact: false,
                            })
                          }
                        >
                          <div
                            className="link"
                            onClick={() => handleServiceClick(item)}
                          >
                            {item.logo == null ? (
                        ""
                      ) : (
                            <img src={baseUrl + item.logo} alt={item.title}
                            //  loading="lazy" 
                             /> )}
                            {item.title}
                          </div>
                        </a>
                        {item.subservices.length > 0 && showSubMenu && (
                          <AiOutlineDown className="dropdownopener" />
                        )}
                        <ul className="dropdown-menu dropdown-menu-level-2">
                          {item.subservices.map((data) => (
                            <li
                              className="nav-item dropend hasdropdown level-2-list"
                              id={`tab-${data.uuid}`}
                            >
                              {data.custom_url === "/" ? (
                                <a
                                  className="dropdown-item"
                                  href="/"
                                  style={{
                                    display: showSubMenu ? "inline-block" : "none",
                                     ////////// -- code commented --/////////
                                     // ? "inline-block"                   //
                                     // : "none",                          //
                                     ////////// -- code commented --/////////
                                  }}
                                  onClick={() => {
                                    setIsMenuOpen(false);
                                    setShowNavbar(false);
                                    setShowSubMenu(false);
                                    setShowActive({
                                      home: false,
                                      about: false,
                                      services: true,
                                      industries: false,
                                      blog: false,
                                      casestudies: false,
                                      contact: false,
                                    });
                                  }}
                                >
                                  {" "}
                                  {data.name}{" "}
                                </a>
                              ) : (
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    navigate(
                                      `/services/${item.slug}/${data.slug}`,
                                      {
                                        state: {
                                          subid: data.service_id,
                                          subserviceid: data.sub_service_id,
                                          id: data.uuid,
                                        },
                                      }
                                    );
                                    setIsMenuOpen(false);
                                    setShowNavbar(false);
                                    setShowSubMenu(false);
                                    setShowActive({
                                      home: false,
                                      about: false,
                                      services: true,
                                      industries: false,
                                      blog: false,
                                      casestudies: false,
                                      contact: false,
                                    });
                                  }}
                                  style={{
                                    display: showSubMenu ? "inline-block" : "none",
                                  }}
                                >
                                  {" "}
                                  {data.name}{" "}
                                </button>
                              )}
                              {data.innerservices.length > 0 && showSubMenu && (
                                <AiOutlineDown className="dropdownopener" />
                              )}
                              <ul className="dropdown-menu dropdown-menu-level-3">
                                {data.innerservices.map((object) => (
                                  <li id={`tab-${object.uuid}`} className="level-3-list">
                                    {object.custom_url === "/" ? (
                                      <a
                                        className="dropdown-item"
                                        href="/"
                                        style={{
                                          display: showSubMenu ? "inline-block" : "none"
                                        }}
                                        onClick={() => {
                                          setShowActive({
                                            home: false,
                                            about: false,
                                            services: true,
                                            industries: false,
                                            blog: false,
                                            casestudies: false,
                                            contact: false,
                                          });
                                          setShowSubMenu(false);
                                          setShowNavbar(false);
                                        }}
                                      >
                                        {" "}
                                        {object.name}{" "}
                                      </a>
                                    ) : (
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          navigate(
                                            `/services/${item.slug}/${data.slug}/${object.slug}`,
                                            {}
                                          );
                                          setShowActive({
                                            home: false,
                                            about: false,
                                            services: true,
                                            industries: false,
                                            blog: false,
                                            casestudies: false,
                                            contact: false,
                                          });
                                          setShowSubMenu(false);
                                          setShowNavbar(false);
                                        }}
                                        style={{
                                          display: showSubMenu ? "inline-block" : "none"
                                        }}
                                      >
                                        {" "}
                                        {object.name}{" "}
                                      </button>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </li>
              <li className="nav-item dropdown hasdropdown megamenu ind_menu-items">
                <a
                  className={
                    showActive.industries
                      ? "nav-link active dropdown-toggle firstparent"
                      : "nav-link dropdown-toggle firstparent"
                  }
                  // href="javascript:void(0);"
                >
                  Industries
                </a>
                <AiOutlineDown
                  className="dropdownopener"
                  onClick={() => setShowNavbar(true)}
                />
                <ul className="dropdown-menu dropdown-menu-level-1_ px-3" style={{flexDirection: 'column'}}>
                  {industries_data.map((industry) => (
                    <li
                      id={`tab-${industry.uuid}`}
                      className="nav-item dropend level-1-list_"
                      key={industry.uuid}
                    >
                      <a
                        className="nav-link  dropdown-toggle"
                        // href="javascript:void(0)"
                        ////////// -- code commented --/////////
                        style={{ display: showNavbar ? "inline-block" : "none",
                        // ? "block" : "none" 
                      }}
                        ////////// -- code commented --/////////
                        onClick={() =>
                          setShowActive({
                            home: false,
                            about: false,
                            services: false,
                            industries: true,
                            blog: false,
                            casestudies: false,
                            contact: false,
                          })
                        }
                      >
                        <div
                          className="link"
                          onClick={() => handleIndustryClick(industry)}
                        >
                          {" "}
                          {industry.name}
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item">
          <Link className={showActive.blog?"nav-link active ":"nav-link "} to="/blogs" 
          onClick={()=>{setShowActive({home:false,about:false,services:false,industries:false,blog:true,casestudies:false,contact:false})
          closeMenu()}}>Blogs</Link>
        </li>
        <li className="nav-item">
          <Link className={showActive.casestudies?"nav-link active ":"nav-link "} to="/casestudies" 
          onClick={()=>{setShowActive({home:false,about:false,services:false,industries:false,blog:false,casestudies:true,contact:false})
          closeMenu()}}>Case Studies</Link>
        </li>
              <li className="nav-item">
                <Link
                  className={
                    showActive.contact ? "nav-link active " : "nav-link "
                  }
                  to="/contact"
                  onClick={() => {
                    setShowActive({
                      home: false,
                      about: false,
                      services: false,
                      industries: false,
                      blog: false,
                      casestudies: false,
                      contact: true,
                    });
                    closeMenu();
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>



{/* NEW MENU */}
<div className="wrappernnn">
  <nav className="nnn">
    <input
      type="checkbox"
      id="menu"
      name="menu"
      className="m-menu__checkbox"
      checked={isMenuOpenFirst}
      onChange={() => setMenuOpenFirst(!isMenuOpenFirst)}
    />
    <label className="m-menu__toggle" for="menu">
      <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
    </label>
    <label className="m-menu__overlay" for="menu">Intellect.Works</label>
  
    <div className={`m-menu${isMenuOpenFirst ? ' open' : ''}`}>
      <div className="m-menu__header">
        <label className="m-menu__toggle" for="menu">
          <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </label>    
        <span>Intellect.Works</span>
      </div>
      <ul>
        <li><a className="px-0" href="/">Home</a></li>
        <li><a className="px-0" href="/about">About Us</a></li>
        <li>
          <div className="a-label__chevron">Services</div>
          <label className="a-label__chevron" for="item-services"></label>
          {service_data.length > 0 && (
            <>
          <input type="checkbox" 
          id="item-services" 
          name="item-services" 
          className="m-menu__checkbox" 
          />
          <div className="m-menu">
            <div className="m-menu__header">
              <label className="m-menu__toggle" for="item-services">
                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs">
                  <path d="M19 12H6M12 5l-7 7 7 7"/>
                </svg>
              </label>
              <span>Services</span>
            </div>
            <ul className="level-1">
              {service_data && service_data.map((item) => (
                <li>
                <div className="a-label__chevron" onClick={() => handleServiceClick(item)}>{item.title}</div>
                  <label className="a-label__chevron" htmlFor={`item-${item.uuid}`}></label>
                  {item.subservices.length > 0 && (
                    <>
                    <input type="checkbox" 
                    id={`item-${item.uuid}`} 
                    name="item-2" 
                    className="m-menu__checkbox" 
                    />
                    <div className="m-menu">
                      <div className="m-menu__header">
                        <label className="m-menu__toggle" htmlFor={`item-${item.uuid}`}><svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs"><path d="M19 12H6M12 5l-7 7 7 7"/></svg>
                        </label>
                        <span>{item.title}</span>
                      </div>
                        <ul className="level-2">
                          {item.subservices.map((data) => (
                            <li>
                            <div className="a-label__chevron" onClick={() => {
                                          navigate(
                                            `/services/${item.slug}/${data.slug}`,
                                            {
                                              state: {
                                                subid: data.service_id,
                                                subserviceid: data.sub_service_id,
                                                id: data.uuid,
                                              },
                                            }
                                          );
                                          resetMenu();
                                          setMenuOpenFirst(false);
                                          setMenuOpenSecond(false);
                                          
                                        }}>{data.name}</div>
                              {data.innerservices.length > 0 && (
                                <label className="a-label__chevron" htmlFor={`item-${data.uuid}`} ></label>
                              )}
                              {data.innerservices.length > 0 && (
                                <>
                                  <input type="checkbox" 
                                    id={`item-${data.uuid}`} 
                                    name="item-2" 
                                    className="m-menu__checkbox" 
                                  />
                                  <div className="m-menu">
                                    <div className="m-menu__header">
                                      <label className="m-menu__toggle" for={`item-${data.uuid}`}><svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="butt" stroke-linejoin="arcs"><path d="M19 12H6M12 5l-7 7 7 7"/></svg>
                                      </label>
                                      <span>{data.name}{" "}</span>
                                    </div>
                                      <ul className="level-3">
                                        {data.innerservices.map((object) => (
                                          <li>
                                          <div className="a-label__chevron" onClick={() => {
                                            navigate(
                                              `/services/${item.slug}/${data.slug}/${object.slug}`,
                                              {}
                                            );
                                            resetMenu();
                                            setMenuOpenFirst(false);
                                          setMenuOpenSecond(false);
                                          
                                          }}>{object.name}</div>
                                          <label ></label></li>
                                        ))}
                                      </ul>
                                  </div>
                                </> 
                              )}            
                            </li>
                          ))}
                        </ul>
                    </div> 
                    </>
                  )}             
                </li>
              ))}
            </ul>
          </div>
          </>
          )}
        </li>
        <li>
          <div className="a-label__chevron">Industries</div>
          <label className="a-label__chevron" htmlFor="item-industries"></label>
          <input
            type="checkbox"
            id="item-industries"
            name="item-industries"
            className="m-menu__checkbox"
            checked={isMenuOpenSecond}
            onChange={() => setMenuOpenSecond(!isMenuOpenSecond)}
          />
          <div className={`m-menu${isMenuOpenSecond ? ' open' : ''}`}>
            <div className="m-menu__header">
              <label className="m-menu__toggle" htmlFor="item-industries">
                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs">
                  <path d="M19 12H6M12 5l-7 7 7 7" />
                </svg>
              </label>
              <span>Industries</span>
            </div>
            <ul>
              {industries_data.map((industry) => (
                <li key={industry.name}>
                  <label onClick={() => handleIndustryClick(industry)}>{industry.name}</label>
                </li>
              ))}
            </ul>
          </div>
        </li>
        <li><a className="px-0" href="/blogs">Blogs</a></li>
        <li><a className="px-0" href="/casestudies">Case Studies</a></li>
        <li><a className="px-0" href="/contact">Contact Us</a></li>
      </ul>  
    </div>
  </nav>
  <a className="navbar-brand fw-bold" href="/">
    <img src={baseUrl + logo.logo} alt="Intellect.Works" width="150" height="66" />
  </a>
</div>
    </>
  );
};
export default Navbars;