import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import "./style.css";
import "./blog.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';

function BlogPost() {
  const [title, setTitle] = useState();
  const [description, setDescriptionName] = useState();
  const [relatedBlogs, setRelatedBogs] = useState();
  const [image, setSettingImage] = useState();
  const [seo, setSeo] = useState("");
  const [author, setAuthor] = useState("");
  const [blog_date, setBlog_date] = useState("");
  const [blogData, setBlogData] = useState([]);
  const baseUrl = "https://backend.react.nvds.in/storage/";
  const urlParts = window.location.href.split("/");
  const blogSlug = urlParts[urlParts.length - 1];
  const navigate = useNavigate();
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const apiUrl = `https://backend.react.nvds.in/api/blogs/${blogSlug}`;

    AOS.init();

    const blogMain = async () => {
      const { data } = await axios.get(apiUrl);
      const seo = data.data.blogLists;
      setSeo(seo);
      const title = data.data.blogLists.title;
      setTitle(title);
      const description = data.data.blogLists.description;
      setDescriptionName(description);
      const image = data.data.blogLists.image;
      setSettingImage(image);
      const relatedBlogs = data.data.related_blogs;
      setRelatedBogs(relatedBlogs);
      const blog_date = data.data.blogLists.blog_date;
      setBlog_date(blog_date);
      const author = data.data.blogLists.author;
      setAuthor(author);
    };
    blogMain();
  }, [blogSlug]);

  useEffect(() => {
    AOS.init();
    const listing = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.react.nvds.in/api/blogs"
        );
        const blogData = data.data.blogLists;
        // if (Array.isArray(blogLists)) {
        setBlogData(blogData);
        // }
      } catch (error) {
        console.error("API Error:", error);
      }
    };
    listing();
    setContentLoaded(true);
  }, [blogSlug]);

  const handleBlogClick = (blog) => {
    navigate(`/blogs/${blog.slug}`, {});
  };

  return (
    <>
     {seo && (
    <Helmet>
      {seo.seo_description !== null && (
        <meta name="description" content={seo.seo_description} />
      )}
      {seo.seo_keywords !== null && (
        <meta name="keywords" content={seo.seo_keywords} />
      )}
      {seo.seo_title !== null && (
        <title>{seo.seo_title}</title>
      )}
      {seo.canonical_tag !== null && (
        <link
        rel="canonical"
        href={seo.canonical_tag} 
      />
      )}
      {seo.schema !== null && (
        <script type="application/ld+json">
         {seo.schema}
          </script>
      )}
    </Helmet>
  )}
  {image && (
      <section className="content single-blog-details bgc-primary">
        <div className="container justify-content-center blog-wrapr">
          <div className="content-full">
          {image == null ? (
                        ""
                      ) : (
            <img src={baseUrl + image} alt="images" loading="lazy"/> )}
            {title && (
            <h2
              className="mt-4 text-light text-left"
              data-aos="fade-up"
              data-aos-duration="1000"
              style={{ textAlign: 'left'}}
            >
              {title}
            </h2>)}
            {author && blog_date && (
              <p data-aos="fade-up" data-aos-duration="1000"><small className="text-white" >
                <span style={{fontWeight: '300'}}>Published by</span> <b className="text-blue text-capitalize">{author}</b>  <span style={{fontWeight: '300'}}>posted on</span> <b className="text-blue">{blog_date}</b>
              </small></p>
            )}
            {description && (
              <div class="pl-60 ml--60">
              <p className="text-white" dir="ltr"
                data-aos="fade-up"
                data-aos-duration="1000"
                dangerouslySetInnerHTML={{ __html: description }} 
              ></p></div>
            )}
          </div>
        </div>
      </section>)}
      {relatedBlogs && relatedBlogs.length > 0 && (
      <section className="content related-blog">
        <div
          className="container-fluid mb-5"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 className="text-primary">Related Posts</h5>
        </div>
        <div
          className="container justify-content-center blog-wrapr"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {relatedBlogs &&
            relatedBlogs.map((blog, index) => (
              <div className="item">
                <div
                  key={index}
                  onClick={() => handleBlogClick(blog)}
                  className="link-whole"
                  rel="noopener noreferrer"
                >
                  <div
                  className="post-img"
                  style={{
                    backgroundImage: `url(${baseUrl + blog.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>      
                  <h4>{blog.title}</h4>
                  <p style={{ color: "#8e8e8e" }}>{blog.slug}</p>
                  <span className="readmore">
                    Read more&nbsp;
                    <BsArrowRight />
                  </span>
                </div>
              </div>
            ))}
        </div>  
      </section>)}
      {contentLoaded && <Footer initiallyHidden={false} />}
    </>
  );
}
export default BlogPost;
