// CookieConsent.js
import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [consentGiven, setConsentGiven] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(true);

  // Check if the user has previously given consent
  useEffect(() => {
    const userConsent = localStorage.getItem('cookieConsent');
    if (userConsent) {
      setConsentGiven(true);
      setBannerVisible(false); // Hide the banner if consent was given
    }
  }, []);

  const handleConsent = () => {
    // Set a cookie or local storage item to remember user consent
    localStorage.setItem('cookieConsent', 'true');
    setConsentGiven(true);
    setBannerVisible(false); // Hide the banner after accepting
  };

  // const handleDecline = () => {
  //   // Handle what should happen when the user declines
  //   // For example, you can display a message or take other actions
  //   alert('You declined cookies.');
  //   setBannerVisible(false); // Hide the banner after declining
  // };

  if (!bannerVisible || consentGiven) {
    return null; // Do not display the banner if consent is given or if it's hidden
  }

  return (
    <div className="cookie-consent">
      <p>This website uses cookies to improve your experience.</p>
<div>
        <button className='accept' onClick={handleConsent}>Accept</button>
        <button className='decline' onClick={handleConsent}>Decline</button>
        </div>
    </div>
  );
};

export default CookieConsent;
